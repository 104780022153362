import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Typography,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Divider,
  Select,
  message,
} from 'antd'
import { useTranslation } from 'react-i18next'
import {
  emailRegex,
  signinStep,
  signInType,
  urlRegex,
  intigerRegex,
  executionModalList,
  teamSizeList,
  roleList,
} from '../../utils/constants'
import { authenticateUser } from '../../services/user'
import { get } from 'lodash'
import {
  checkCorporateEmailId,
  redirectToCubytsApps,
  storeUserIdInLocalStorage,
} from '../../utils/common'
import './SignIn.less'
import './RequestAccessForm.less'
import ReCaptchaTwo from '../../components/recaptcha/ReCaptchaTwo'
import { Link, useHistory } from 'react-router-dom'
import { postEarlyAccessUser } from '../../services/earlyAccess'
import { RequestComplete } from './RequestComplete'

const { Text, Paragraph } = Typography
const { Option } = Select
const { TextArea } = Input

const userNamePasswordInvalidErrorConstant = 'USERNAME_PASSWORD_INVALID_ERROR'

const getFilteredSearchParam = (searchParams) => {
  const searchParamInstance = new URLSearchParams(searchParams)
  searchParamInstance.delete('challenge')
  searchParamInstance.delete('external_authentication')
  return `?${searchParamInstance.toString()}`
}

const RequestAccessForm = (props) => {
  const { oldPath, setCurrentSignInSetup, setUserToken } = props
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [errorState, setErrorState] = useState({ type: '', message: '' })
  const [formDisabled, setFormDisabled] = useState(false)
  const [requestComplete, setRequestComplete] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const recaptchRef = useRef()
  let history = useHistory()

  const values = Form.useWatch([], form)
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true)
        },
        () => {
          setSubmittable(false)
        }
      )
  }, [values])

  useEffect(() => {
    if (oldPath) {
      let userEmailFromURL = new URLSearchParams(oldPath).get('ue') || ''

      if (userEmailFromURL) {
        userEmailFromURL = Buffer.from(userEmailFromURL, 'base64').toString()
        form.setFieldsValue({
          emailId: userEmailFromURL,
        })
      }
    }
  }, [])

  const getFormattedSignUpPayload = (values) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role,
      emailId: values.emailId,
      companyName: values.companyName,
      companyUrl: values.companyUrl,
      onBehalfOf: 'USER',
      extra: {
        executionModel: values.executionModel,
        teamMember: values.teamMember,
      },
      message: values.message,
      requestFrom: 'WEBSITE',
    }
    return payload
  }

  const onFinish = async (values) => {
    setFormDisabled(true)
    setIsLoading(true)
    const token = await recaptchRef.current.generateToken()
    const formattedPayload = getFormattedSignUpPayload(values)
    postEarlyAccessUser(formattedPayload, token)
      .then(() => {
        form.resetFields()
        message.success('Early access request submission successful.')
        setRequestComplete(true)
      })
      .catch((error) => {
        const { code: errorCode, type: errorType } = get(
          error,
          'response.data.error',
          {}
        )

        if (errorCode === 409 || errorType === 'alreadyExists') {
          message.error(t('common.accountAlreadyExists'))
        }

        if (errorCode === 400 || errorType === 'nonCorporateEmailId') {
          message.error(t('signUp.nonCorporateEmailId'))
        } else {
          message.error(t('Sorry something went wrong, please try again'))
        }
      })
      .finally(() => {
        setFormDisabled(false)
        setIsLoading(false)
      })
  }
  return (
    <>
      {!requestComplete ? (
        <div className="signInFormContainer">
          <ReCaptchaTwo ref={recaptchRef} />
          <div className='block mb-5'>
            <div className="text-3xl font-thin text-white">
              {t('backgroundWrapper.heading1')} {t('backgroundWrapper.heading2')} {t('backgroundWrapper.heading3')}
            </div>
          </div>
          <div className="signInTitle">
            {t('requestAccess.form.title')}
            </div>
          <div className="text-very-light subtitle">
            {t('requestAccess.form.subtitle1')}
          </div>
          {/* <Paragraph className="subtitle ">
        <span>{t('signIn.form.subtitle2.message1')}</span>
        <Link
          to={`/request_access${getFilteredSearchParam(
            history.location.search
          )}`}
        >{` ${t('signIn.form.subtitle2.message2')}`}</Link>
      </Paragraph> */}
          <Form
            name="requestAccessForm"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            size="large"
            form={form}
            disabled={formDisabled}
            className="requestAccessForm"
            requiredMark={false}
          >
            <Row gutter={[12, 24]} className="mb-6">
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={t('requestAccess.form.firstName')}
                  name="firstName"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t('requestAccess.form.emptyFirstNameError'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('requestAccess.form.firstNameHolder')}
                  />
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={t('requestAccess.form.lastName')}
                  name="lastName"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t('requestAccess.form.emptyLastNameError'),
                    },
                  ]}
                >
                  <Input placeholder={t('requestAccess.form.lastNameHolder')} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('requestAccess.form.email')}
                  name="emailId"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t('signIn.form.noEmailError'),
                    },
                    {
                      pattern: emailRegex,
                      message: t('signIn.form.invalidEmailError'),
                    },
                    {
                      validator: (_, value) =>
                        checkCorporateEmailId(value) ||
                        value?.trim() === '' ||
                        !value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(t('signIn.form.validWorkEmail'))
                            ),
                    },
                  ]}
                >
                  <Input placeholder={t('requestAccess.form.emailHolder')} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('requestAccess.form.role')}
                  name="role"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t('requestAccess.form.emptyRoleError'),
                    },
                  ]}
                  className={'cbSelect'}
                >
                  <Select
                    placeholder={t('requestAccess.form.roleHolder')}
                    options={roleList}
                  />
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={t('requestAccess.form.company.name')}
                  name="companyName"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t('requestAccess.form.company.emptyNameError'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('requestAccess.form.company.nameHolder')}
                  />
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={t('requestAccess.form.company.url')}
                  name="companyUrl"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'requestAccess.form.company.emptyWebsiteError'
                      ),
                    },
                    {
                      pattern: urlRegex,
                      message: t('signIn.form.invalidUrlError'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('requestAccess.form.company.urlHolder')}
                  />
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name="executionModel"
                  label={t('requestAccess.form.company.executionModel')}
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'requestAccess.form.company.emptyExecutionModelError'
                      ),
                    },
                  ]}
                  className={'cbSelect'}
                >
                  <Select
                    placeholder={t(
                      'requestAccess.form.company.executionModelHolder'
                    )}
                    allowClear
                    options={executionModalList}
                  />
                </Form.Item>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  label={t('requestAccess.form.company.teamSize')}
                  name="teamMember"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'requestAccess.form.company.emptyTeamSizeError'
                      ),
                    },
                  ]}
                  className={'cbSelect'}
                >
                  <Select
                    placeholder={t('requestAccess.form.company.teamSizeHolder')}
                    showSearch
                    options={teamSizeList}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('requestAccess.form.message')}
                  name="message"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: false,
                      message: t('signIn.form.noEmailError'),
                    },
                  ]}
                >
                  <TextArea
                    placeholder={t('requestAccess.form.messageHolder')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="justify-start flex mt-8">
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                block
                disabled={!submittable}
                className={'requestAccessFormButton'}
              >
                {t('requestAccess.form.submit')}
              </Button>
            </Form.Item>

            {errorState && errorState.type === signInType.cubyts && (
              <Alert
                style={{ marginTop: '-15px' }}
                message={
                  errorState.message ===
                  userNamePasswordInvalidErrorConstant ? (
                    <div>
                      <Text>
                        {t('signIn.form.userNamePasswordIncorrect.message1')}
                      </Text>
                      <Paragraph>
                        {t('signIn.form.userNamePasswordIncorrect.message2')}
                        <Link
                          to={`/forgotpassword${getFilteredSearchParam(
                            history.location.search
                          )}`}
                        >
                          {t('signIn.form.userNamePasswordIncorrect.message3')}
                        </Link>
                      </Paragraph>
                    </div>
                  ) : (
                    <Paragraph>{errorState.message}</Paragraph>
                  )
                }
                type="error"
              />
            )}
          </Form>
        </div>
      ) : (
        <RequestComplete />
      )}
    </>
  )
}

RequestAccessForm.propTypes = {
  oldPath: PropTypes.string,
  setCurrentSignInSetup: PropTypes.func,
  setUserToken: PropTypes.func,
}

RequestAccessForm.defaultProps = {
  oldPath: '',
  setCurrentSignInSetup: () => {
    //empty function
  },
  setUserToken: () => {
    //empty function
  },
}
export default RequestAccessForm
