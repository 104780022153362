import axios from '../utils/axios'

export const getGoogleAuthURL = (lastPath = '', isUserSignUp = false, couponId, showAssessment = false) =>
  axios({
    url: `/external/google-api/auth`,
    params: {
      sourceUrl: lastPath,
      isUserSignUp,
      ...(isUserSignUp && couponId && {couponId}),
       ...(isUserSignUp && showAssessment && { showAssessment }),
    }
  })

export const setGoogleToken = (code = '', workspaceId = '', tenantId = '') => {
  return axios({
    url: `/external/google-api/setToken`,
    method: 'POST',
    ...(workspaceId || tenantId
      ? { headers: { 'X-WorkspaceId': workspaceId, 'X-TenantId': tenantId } }
      : {}),
    data: {
      code: code,
    },
  })
}

export const googleSignUp = (reqBody = {}) =>
  axios({
    url: `/users/signup/google`,
    method: 'POST',
    data: reqBody,
    apiVersion: '/v2',
  })
