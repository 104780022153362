import { get } from 'lodash'
import React, { useEffect } from 'react'
import CommonSkeleton from '../../components/CommonSkeleton/CommonSkeleton'
import { redirectToCubytsApps } from '../../utils/common'
import { authStatus } from '../../utils/constants'

const MicrosoftAuth = () => {
  useEffect(() => {
    // Get response from the API
    let microsoftAuthResponseObject = JSON.parse(
      new URLSearchParams(window.location.search).get('response')
      )
    // Destructure all the state objects that were set during the build url action
    let { isDocumentRequest = false, error = '', integration = '' } = get(
      microsoftAuthResponseObject,
      'metaDataIDForDocument.metadataValue.state' , {}
    )
    if (
      !microsoftAuthResponseObject.error &&
      (isDocumentRequest === true || isDocumentRequest === 'true')
    ) {
      // Redirect to the target url - basically this is the handoff to the induct app - This is an existing function which will do the rest.
      // get the source url from the response and build the target url
      const sourceUrl = decodeURIComponent(
        microsoftAuthResponseObject.sourceUrl
      )
      const redirectionURL = `${sourceUrl}${
        sourceUrl.includes('?') ? '&' : '?'
      }msAuthStatus=SUCCESS`
      if (redirectionURL.includes('closeWindowAfterSignIn')) {
        window.close()
      } else {
        window.location.assign(redirectionURL)
      }
    }
    // Happy flow for Sign up/Sign in
    else if (!microsoftAuthResponseObject.error) {
      // Set the jwt and user id in the localstorage
      localStorage.setItem('d', microsoftAuthResponseObject.authenticationToken)
      localStorage.setItem('userId', microsoftAuthResponseObject.userId)

      // get the source url from the response and build the target url
      if (microsoftAuthResponseObject.isSignup) {
        // Redirect to the target url - basically this is the handoff to the induct app - This is an existing function which will do the rest.
        let redirectionURL = new URL(microsoftAuthResponseObject.sourceUrl)
        redirectionURL = new URLSearchParams(redirectionURL.search).get('ru')
        redirectToCubytsApps(
          microsoftAuthResponseObject.authenticationToken,
          '',
          redirectionURL
        )
      } else {
        const sourceUrl = decodeURIComponent(
          microsoftAuthResponseObject.sourceUrl
        )
        const redirectionURL = `${sourceUrl}${
          sourceUrl.includes('?') ? '&' : '?'
        }msAuthStatus=SUCCESS`
        window.location.assign(redirectionURL)
      }
      
     
    }
    // Error flow in case there are error in signing up or signing in with MS. The error would have been figured out from the API and the API will return the error here.
    else {
      handleError(
        microsoftAuthResponseObject.error,
        microsoftAuthResponseObject.sourceUrl,
      )
    }
  }, [])

  // Error handling code.
  const handleError = (error, sourceUrl) => {
    // get the source url from the response and build the target url
    let decodedSourceUrl = decodeURIComponent(
      sourceUrl
    )
    decodedSourceUrl = `${decodedSourceUrl}${
      decodedSourceUrl.includes('?') ? '&' : '?'
    }msAuthStatus=${authStatus.failure}`
    decodedSourceUrl = `${decodedSourceUrl}&msAuthMsg=${error.type}`
    window.location.assign(decodedSourceUrl)
  }

  return <CommonSkeleton isLoading={true} />
}

MicrosoftAuth.propTypes = {}

export default MicrosoftAuth
