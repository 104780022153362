import React from 'react'
import PropTypes from 'prop-types'
import './commonSkeleton.less'

const CommonSkeleton = (props) => {
  const { isLoading } = props
  return (
    <>
      {isLoading && (
        <div className={'skeleton_wrapper'}>
          <img
            class="loader-icon"
            src="https://byts-app-uploads.s3.amazonaws.com/public/email_assets/Cubyts_Loader@2x.gif"
            alt="Loading..."
            style={{ width: '160px' }}
          />
        </div>
      )}
    </>
  )
}

CommonSkeleton.propTypes = {
  isLoading: PropTypes.bool,
}
CommonSkeleton.defaultProps = {
  isLoading: true,
}
export default CommonSkeleton
