import { Button, Form, Input, Row, Col, Select, message } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import React, { useState, useEffect } from 'react'
import cubytslogo from '../../assets/icon/common/cubyts-whole-dark-no-bg.svg'
import './EarlyAccess.less'
import { postEarlyAccessUser } from '../../services/earlyAccess'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

const { Option } = Select

const EarlyAccess = () => {
  const { t } = useTranslation()
  const [submittable, setSubmittable] = useState(false)
  const [componentDisabled, setComponentDisabled] = useState(false)

  const [form] = Form.useForm()
  const onFinish = (values) => {
    setComponentDisabled(true)
    setSubmittable(false)
    postEarlyAccessUser({
      ...values,
      onBehalfOf: 'USER',
      approvalStatus: 'APPLIED',
    })
      .then(() => {
        form.resetFields()
        // if (errorCode === 409 || errorType === 'alreadyExists') {
        message.success('Early Access Request Submition Successful')
        // }
      })
      .catch((error) => {
        const { code: errorCode, type: errorType } = get(
          error,
          'response.data.error',
          {}
        )

        if (errorCode === 409 || errorType === 'alreadyExists') {
          message.error(t('common.accountAlreadyExists'))
        }

        if (errorCode === 400 || errorType === 'nonCorporateEmailId') {
          message.error(t('signUp.nonCorporateEmailId'))
        }
      })
      .finally(() => setComponentDisabled(false))
  }

  const values = Form.useWatch([], form)
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true)
        },
        () => {
          setSubmittable(false)
        }
      )
  }, [values])

  return (
    <>
      <PageHeader
        title={
          <img
            alt="logo"
            src={cubytslogo}
            style={{ paddingLeft: 30, paddingTop: 10 }}
          />
        }
      />
      <Row justify="center" align="middle">
        <Col>
          <h1>Request for Early Access</h1>
        </Col>
      </Row>
      <div className="earlyAccesscard">
        <Form
          form={form}
          layout={'vertical'}
          name="register"
          onFinish={onFinish}
          disabled={componentDisabled}
          scrollToFirstError
        >
          <Row gutter={[12]}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="role"
                label="Your Role"
                rules={[
                  {
                    required: true,
                    message: 'Please select your Role!',
                  },
                ]}
              >
                <Select placeholder="Please select a Role">
                  <Option value="PRODUCT_LEADERSHIP">Product Leadership</Option>
                  <Option value="HEAD_OF_PRODUCT">Head of Product</Option>
                  <Option value="PRODUCT_OWNER">Product Owner</Option>
                  <Option value="PRODUCT_MANAGER">Product Manager</Option>
                  <Option value="SCRUM_MASTER">Scrum Master</Option>
                  <Option value="OTHERS">Others</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="emailId"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="companyUrl"
                label="Company url"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="intro" label="Intro">
                <Input.TextArea showCount maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="center" align="middle">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!submittable}
                  >
                    Submit Request
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

EarlyAccess.propTypes = {}

export default EarlyAccess
