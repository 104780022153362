import axios from '../utils/axios';
import { SUB_SYSTEM_NAME } from '../utils/constants';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
 export const getEncryptedData = ({ reqBody =[]}) => {
    return axios({
      method: 'POST',
      url: `/cubytsGeek/crypto/encrypt/`,
      data: reqBody,
    });
  };
/**
 * 
 * @param {*} param0 
 * @returns 
 */
 export const getDecryptedData = ( reqBody={}, token) => {
    return axios({
      method: 'POST',
      url: `/cubytsGeek/crypto/decrypt/`,
      data: reqBody,
      subSystemName: SUB_SYSTEM_NAME.BYTS,
      headers: {
        'X-Recaptcha': token,
      },

    });
  };