import { capitalize } from 'lodash'
import { EXTERNAL_AUTH_TYPE } from './constants'
import { nonCorporateEmails } from './nonCorporateEmails'
export const colorPalette = {
  A: '#DADAF4',
  B: '#F9D0D5',
  C: '#FCD8BD',
  D: '#D9F4EA',
  E: '#D0F1FF',
  F: '#FDF0C1',
  G: '#DADAF4',
  H: '#F9D0D5',
  I: '#FCD8BD',
  J: '#D9F4EA',
  K: '#D0F1FF',
  L: '#FDF0C1',
  M: '#DADAF4',
  N: '#F9D0D5',
  O: '#FCD8BD',
  P: '#D9F4EA',
  Q: '#D0F1FF',
  R: '#FDF0C1',
  S: '#DADAF4',
  T: '#F9D0D5',
  U: '#FCD8BD',
  V: '#D9F4EA',
  W: '#D0F1FF',
  X: '#FDF0C1',
  Y: '#DADAF4',
  Z: '#F9D0D5',
}
export const storeUserIdInLocalStorage = (userId = '') => {
  localStorage.setItem('userId', userId)
}

export const redirectToCubytsApps = (jwt, oldPath = '', redirectionalUrl) => {
  let hostName = window.location.hostname
  hostName = hostName.split('.')

  let subdomain = hostName[0].split('-')
  subdomain = subdomain.reverse()[0]
  let cubytsAppURL = process.env.REACT_APP_BYTS_UI_HOST
  if (subdomain.includes('localhost')) {
    cubytsAppURL = 'http://localhost:8000'
    // document.cookie = `a=${jwt};path=/;domain=localhost`;
  }
  // else {
  //   cubytsAppURL = process.env.REACT_APP_BYTS_UI_HOST //`${baseURL}${subdomain}.${baseDomain}`
  //   // document.cookie = `a=${jwt};path=/;secure;domain=${baseDomain}`;
  // }

  if (jwt && jwt !== 'undefined') {
    if (oldPath) {
      // cubytsAppURL += `${oldPath}&d=${jwt}`
      cubytsAppURL += oldPath
      if (oldPath.includes('?')) {
        cubytsAppURL += `&d=${jwt}&wsm=true`
      } else {
        cubytsAppURL += `?d=${jwt}&wsm=true`
      }
    } else {
      cubytsAppURL += `?d=${jwt}&wsm=true`
    }
  }

  if (redirectionalUrl) {
    cubytsAppURL += `&ru=${encodeURI(redirectionalUrl)}`
  }

  if (
    new URL(window.location.href).searchParams.get('q') &&
    new URL(window.location.href).searchParams.get('q') === 'assessment'
  ) {
    cubytsAppURL += `&q=assessment`
  }

  if (jwt) {
    localStorage.setItem('d', jwt)
  }
  console.log('redirectToCubytsApps >> ', cubytsAppURL)
  const externalAuthType = new URLSearchParams(window.location.search).get(
    'external_authentication'
  )
  const codeChallenge = new URLSearchParams(window.location.search).get(
    'challenge'
  )
  if (externalAuthType && codeChallenge) {
    cubytsAppURL = getExternalAuthRedirect(externalAuthType, codeChallenge)
  }
  window.location.assign(cubytsAppURL)
}

export const getExternalAuthRedirect = (externalAuthType, codeChallenge) => {
  switch (externalAuthType) {
    case EXTERNAL_AUTH_TYPE.FIGMA:
      return `/authenticate/figma/?challenge=${codeChallenge}`
    default:
      return ''
  }
}

export const getJWTFromLocalStorage = () => {
  return localStorage.getItem('d')
}

export const getUserIdFromLocalStorage = () => {
  return localStorage.getItem('userId')
}

export const getInitialsAvatar = (name) => {
  let initials = name
  try {
    initials = name
      .trim()
      .replace(/[^A-Za-z0-9À-ÿ ]/gi, '')
      .replace(/ +/gi, ' ')
      .split(/ /)
      .filter((word) => word.length > 0)
      .reduce((acc, word) => acc + word[0], '')
      .substring(0, 2)
      .toUpperCase()

    if (!initials) {
      return name.substring(0, 2).toUpperCase()
    }

    return initials
  } catch (error) {
    return name ? name.substring(0, 2).toUpperCase() : ''
  }
}

export const parseJSONStringIntoObject = (
  input = '',
  isReturnEmpty = false
) => {
  try {
    if (typeof input === 'string') {
      input = JSON.parse(input)
    }
    return input
  } catch (e) {
    return isReturnEmpty ? {} : input
  }
}

export const getUserNameFromEmail = (email = '') => {
  return email.trim().toLowerCase().split('@')[0]
}

export const getFirstAndLastNameFromEmail = (email = '') => {
  let userName = getUserNameFromEmail(email) || ''
  userName = userName.split('.')

  return {
    firstName: capitalize(userName[0]),
    ...(userName.length > 1 && { lastName: capitalize(userName[1]) }),
  }
}
export const getDomainFromEmail = (emailId = '') => {

  const emails = emailId ? emailId.trim().toLowerCase().split('@') : ''
  return emails.length > 1 ? emails[emails.length - 1] : ''
}

export const checkCorporateEmailId = (userEmailId) => {
  // if (
  //   process.env.ALLOW_CORPORATE_EMAIL_ONLY === true ||
  //   process.env.ALLOW_CORPORATE_EMAIL_ONLY === 'true'
  // ) {
    if (userEmailId) {
      // debugger
      let domain = getDomainFromEmail(userEmailId) || ''
      // domain = domain.trim().toLowerCase().split('.')
      // domain = domain[0]
      // const  ema= process.env.EMAIL_SERVICE_PROVIDER || []
      domain = domain.trim().toLowerCase()
      return nonCorporateEmails.includes(domain) ? false : true
    }

    return false
  // }
  // return true
}