import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import EarlyAccess from './pages/earlyaccess/EarlyAccess'
import SignIn from './pages/signin/SignIn'
import ForgotPassword from './pages/forgotPassword/FogotPassword'
import ResetForgotPassword from './pages/resetForgotPassword/ResetForgotPassword'
import GoogleAuth from './pages/googleAuth/GoogleAuth'
// import SignUp from './pages/SignUp/SignUp'
// import OnBoardingSteps from './pages/onBoarding/onBoardingSteps/OnBoardingSteps'
// import EmailVerificationLoader from './pages/EmailVerification/EmailVerificationLoader'
import AuthenticateFigma from './pages/externalAuthentication/AuthenticateFigma'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import MicrosoftAuth from './pages/microsoftAuth/MicrosoftAuth'
import RequestAccess from './pages/signin/RequestAccess'
import { ConfigProvider } from 'antd'

const App = () => {
  const [oldPath, setOldPath] = useState('')
  useEffect(() => {
    handleGoogleTagManagerMarketing()
    const op = new URLSearchParams(window.location.search).get('u') || ''
    if (op) {
      setOldPath(op)
    }
  }, [])

  const handleGoogleTagManagerMarketing = () => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_KEY })
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Nunito Sans',
        },
      }}
    >
      <Router>
        <Switch>
          {/* <Route exact path="/signup" component={(props) => <SignUp {...props} oldPath={oldPath} />}></Route>
          <Route exact path="/emailverification" component={(props) => <EmailVerificationLoader {...props} oldPath={oldPath} />}></Route>
          <Route exact path="/onboardingsteps" component={(props) => <OnBoardingSteps {...props} oldPath={oldPath} />}></Route> */}
          <Route
            exact
            path="/login"
            component={(props) => <SignIn {...props} oldPath={oldPath} />}
          ></Route>
          <Route
            exact
            path="/request_access"
            component={(props) => (
              <RequestAccess {...props} oldPath={oldPath} />
            )}
          ></Route>
          <Route exact path="/googleAuth" component={GoogleAuth}></Route>
          <Route exact path="/microsoftAuth" component={MicrosoftAuth}></Route>
          <Route path="/earlyaccessrequest" component={EarlyAccess}></Route>
          <Route
            exact
            path="/forgotpassword"
            component={ForgotPassword}
          ></Route>
          <Route
            exact
            path="/forgotpassword/reset"
            component={ResetForgotPassword}
          ></Route>
          <Route
            exact
            path="/authenticate/figma"
            component={() => (
              <AuthenticatedRoute>
                <AuthenticateFigma />
              </AuthenticatedRoute>
            )}
          ></Route>
          <Redirect to="/login" from="/" />
        </Switch>
      </Router>
    </ConfigProvider>
  )
}

export default App
