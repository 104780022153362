import React, { useState } from 'react'
import './CommonSteps.less'
import { PageHeader } from '@ant-design/pro-layout'
import cubytsLogo from '../../assets/icon/common/cubyts-whole-dark-no-bg.svg'

const CommonStepsHeader = () => {
  return (
    <>
      <PageHeader
        title={<img alt="logo" src={cubytsLogo} style={{ paddingLeft: 40 }} />}
        style={{ position: 'inherit' }}
      />
    </>
  )
}

export default CommonStepsHeader
