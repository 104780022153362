import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enus from '../locales/en-us/en-us'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enus,
      },
      ja: {
        translation: {
          //Japanese translations here
        },
      },
    },
    lng: "en",
    fallbackLng: "en",
  });

export default i18n;