import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import checkIcon from '../../../../assets/icon/common/checked.svg'
import unCheckIcon from '../../../../assets/icon/common/unChecked.svg'
import './PasswordToolTip.less'
const { Text } = Typography

const PasswordToolTip = (props) => {
  const { t } = useTranslation()
  const optionInCheckBox = useMemo(() => {
    return [
      {
        name: t('resetPassword.passworRule.lengthRule'),
        valueKeyName: 'lengthCheck',
      },
      {
        name: t('resetPassword.passworRule.capitalLetter'),
        valueKeyName: 'capitalLetter',
      },
      // {
      //   name: t('resetPassword.passworRule.specialLetter'),
      //   valueKeyName: 'specialLetter',
      // },
      {
        name: t('resetPassword.passworRule.number'),
        valueKeyName: 'number',
      },
    ]
  },[])
 
  return (
    <div className='passwordToolTipContainer'>
      {optionInCheckBox.map((d) => {
        return (
          <div key={d.valueKeyName}>
            <img alt='checkbox' src={props[d.valueKeyName] ? checkIcon : unCheckIcon} />
            <Text className={`${props[d.valueKeyName] ? 'greenColor' : ''}`}>{d.name}</Text>
          </div>
        )
      })}
    </div>
  )
}

PasswordToolTip.propTypes = {
    lengthCheck: PropTypes.bool,
  capitalLetter: PropTypes.bool,
  number: PropTypes.bool,
  specialLetter: PropTypes.bool,
}

PasswordToolTip.defaultProps = {
lengthCheck: false,
  capitalLetter: false,
  number: false,
  specialLetter: false,
}

export default PasswordToolTip
