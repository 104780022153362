import React, { useRef, useState } from 'react'
import BackgroundWrapper from '../../components/backgroundWrapper/BackgroundWrapper'
// import LHSRHSWrapper from '../../components/backgroundWrapper/LHSRHSWrapper'
import RHSCard from '../../components/RHSCard/RHSCard'
import LHSCard from '../../components/LHSCard/LHSCard'
import forgotPasswordIcon from '../../assets/icon/forgotPassword/forgotPassword.svg'
import checkMailIcon from '../../assets/icon/forgotPassword/checkMail.svg'
import { useTranslation } from 'react-i18next'
import { Form, Input, Button, Alert, Spin, Typography,Row, Col } from 'antd'
import { emailRegex } from '../../utils/constants'
import './ForgotPassword.less'
import { sendForgotPasswordMail } from '../../services/user'
import ReCaptchaTwo from '../../components/recaptcha/ReCaptchaTwo'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import CommonSignUpBackground from './../../components/CommonSignUpBackground/CommonSignUpBackground'
const { Paragraph } = Typography

const FogotPassword = () => {
  const recaptchRef = useRef()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [errorState, setErrorState] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [enteredEmailId, setEnteredEmailId] = useState(false)
  const history = useHistory()

  const onFinish = async (value) => {
    setIsLoading(true)
    const token = await recaptchRef.current.generateToken()
    sendForgotPasswordMail(value.emailId.trim(), token)
      .then((result) => {
        if (result && result.status === 200) {
          setEnteredEmailId(value.emailId)
        } else {
          setErrorState(t('common.errorMessage'))
        }
      })
      .catch(() => {
        setErrorState(t('common.errorMessage'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const goToLoginPage = () => {
    history.push(`/login${history.location.search}`)
  }
  return (
    <CommonSignUpBackground signInFlow={true}>
      <ReCaptchaTwo ref={recaptchRef} />
      <Spin spinning={false}>
        {/* <RHSCard> */}
        <Row align={'middle'} justify={'center'} className='w-full'>
          <Row className="forgotPassword" align='start'>
            <Helmet>
              <title>{t('browserTitle.resetPassword')}</title>
            </Helmet>
            <div className="font-extrabold text-2xl text-white">
              {enteredEmailId
                ? t('forgotPassword.checkMail')
                : t('common.forgotPassword')}
            </div>
            {errorState && <Alert type="error" message={errorState} />}
            {enteredEmailId && (
              <Row>
                <Col span={24}>
                <span className="checkMailTitle text-very-light text-sm mt-2 mb-6 flex">{`${t(
                  'forgotPassword.checkMailExist.message1'
                )}${enteredEmailId}${t(
                  'forgotPassword.checkMailExist.message2'
                )}`}</span>
                <Button onClick={goToLoginPage} className="primaryButtonCTAa" type='primary' size='large'>
                  {t('forgotPassword.retrunToLogin')}
                </Button>
                </Col>
              </Row>
            )}
            {!enteredEmailId && (
              <Row>
                <Col span={24}>
                <div className="text-gray-300 font-normal text-sm mt-2 mb-6 flex">{t('forgotPassword.subtitle')}</div>
                <Form
                  form={form}
                  name="forgotPasswordForm"
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                  size="large"
                  requiredMark={false}
                >
                  <Form.Item
                    label={<span className='text-gray-300 font-bold text-sm'>{t('forgotPassword.enterEmail')}</span>}
                    name="emailId"
                    validateTrigger={['onBlur']}
                    rules={[
                      {
                        required: true,
                        message: t('signIn.form.noEmailError'),
                      },
                      {
                        pattern: emailRegex,
                        message: t('signIn.form.invalidEmailError'),
                      },
                    ]}
                  >
                    <Row>
                      <Col span={20}>
                        <Input
                          onChange={(e) =>
                            form.setFieldsValue({
                              ...form.getFieldsValue(),
                              emailId: e.target.value.trim(),
                            })
                          }
                          className='-mt-2'
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row gutter={[24, 12]}>
                      <Col xs={{ span: 24 }} sm={{ span: 10 }} className='flex'>
                      <Button                      
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                          className={'mr-4'}
                        >
                          {t('forgotPassword.sendLink')}
                        </Button>

                      <Button
                          onClick={goToLoginPage}
                          type='secondary'
                          className={'returnToLoginButton'}
                        >
                          {t('forgotPassword.retrunToLogin')}
                        </Button>
                      
                        

                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
                </Col>
              </Row>
            )}
          </Row>
        </Row>
        {/* </RHSCard> */}
      </Spin>
    </CommonSignUpBackground>

    // <BackgroundWrapper>
    //   <ReCaptchaTwo ref={recaptchRef} />
    //   <LHSRHSWrapper
    //     lhsContent={
    //       <LHSCard icon={enteredEmailId ? checkMailIcon : forgotPasswordIcon} />
    //     }
    //     rhsContent={
    //       <Spin spinning={isLoading}>
    //         <RHSCard>
    //           <div className="forgotPassword">
    //             <Helmet>
    //               <title>{t('browserTitle.resetPassword')}</title>
    //             </Helmet>
    //             <div className="titleText">
    //               {enteredEmailId
    //                 ? t('forgotPassword.checkMail')
    //                 : t('common.forgotPassword')}
    //             </div>
    //             {errorState && <Alert type="error" message={errorState} />}
    //             {enteredEmailId && (
    //               <>
    //                 <Paragraph className="checkMailTitle">{`${t(
    //                   'forgotPassword.checkMailExist.message1'
    //                 )}${enteredEmailId}${t(
    //                   'forgotPassword.checkMailExist.message2'
    //                 )}`}</Paragraph>
    //                 <Button
    //                   onClick={goToLoginPage}
    //                   className="secondaryButtonCTA"
    //                 >
    //                   {t('forgotPassword.retrunToLogin')}
    //                 </Button>
    //               </>
    //             )}
    //             {!enteredEmailId && (
    //               <>
    //                 <div className="subtitle">
    //                   {t('forgotPassword.subtitle')}
    //                 </div>
    //                 <Form
    //                   form={form}
    //                   name="forgotPasswordForm"
    //                   layout="vertical"
    //                   onFinish={onFinish}
    //                   autoComplete="off"
    //                   size="large"
    //                 >
    //                   <Form.Item
    //                     label={t('forgotPassword.enterEmail')}
    //                     name="emailId"
    //                     validateTrigger={['onBlur']}
    //                     rules={[
    //                       {
    //                         required: true,
    //                         message: t('signIn.form.noEmailError'),
    //                       },
    //                       {
    //                         pattern: emailRegex,
    //                         message: t('signIn.form.invalidEmailError'),
    //                       },
    //                     ]}
    //                   >
    //                     <Input
    //                       onChange={(e) =>
    //                         form.setFieldsValue({
    //                           ...form.getFieldsValue(),
    //                           emailId: e.target.value.trim(),
    //                         })
    //                       }
    //                     />
    //                   </Form.Item>
    //                   <Form.Item>
    //                     <div className="buttonContainer">
    //                       <Button
    //                         onClick={goToLoginPage}
    //                         className={'returnToLoginButton secondaryButtonCTA'}
    //                       >
    //                         {t('forgotPassword.retrunToLogin')}
    //                       </Button>
    //                       <Button type="primary" htmlType="submit">
    //                         {t('forgotPassword.sendLink')}
    //                       </Button>
    //                     </div>
    //                   </Form.Item>
    //                 </Form>
    //               </>
    //             )}
    //           </div>
    //         </RHSCard>
    //       </Spin>
    //     }
    //   />
    // </BackgroundWrapper>
  )
}

FogotPassword.propTypes = {}

export default FogotPassword
