import axios from '../utils/axios'

export const getMicrosoftAuthURL = async (lastPath = '', isUserSignUp = false, couponId, showAssessment = false) => {
  return axios({
    url: `/ms-auth/build-url`,
    apiVersion: '/v2',
    method: 'GET',
    params: {
      sourceUrl: lastPath,
      isUserSignUp,
      ...(isUserSignUp && couponId && { couponId }),
      ...(isUserSignUp && showAssessment && { showAssessment }),
    }
  })
}
  


