export const emailRegex = /^([A-Za-z0-9_\.'-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
export const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s/$.?#]+\.[^\s]*$/
export const intigerRegex  = /^\d+$/
export const passwordCaptialRuleRegex = /^(?=.*[A-Z]).*$/
export const passwordNumberRuleRegex = /^(?=.*[0-9]).*$/
export const passwordSpecialLetterRuleRegex = /(?=.*?[#!@$%&*])/
// export const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*?[#!@$%&*-])(.{8,}).*$/
export const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(.{8,}).*$/

export const signinStep = {
  signIn: 'SIGNIN',
  resetPassword: 'RESET_PASSWORD',
  userDetails: 'USER_DETAILS',
}

export const userRoles = {
  founder: 'Founder',
  designManager: 'Design Manager',
  designer: 'Designer',
  productManger: 'Product Manager',
  developer: 'Developer',
  others: 'Others',
}

export const ALLOWED_IMAGE_FILE_TYPES_FOR_USER_PROFILE = [
  'image/png',
  'image/jpeg',
  '.png',
  '.jpg',
  '.jpeg',
]

export const signInType = {
  cubyts: 'CUBYTS',
  google: 'GOOGLE',
  microsoft: 'MICROSOFT'
}

export const authStatus = {
  success: 'SUCCESS',
  failure: 'FAILURE',
}

export const emailVerification = {
  verify: 'VERIFY',
  expired: 'EXPIRED',
  verified: 'VERIFIED',
  invalid: 'INVALID',
}

export const SMALL_SCREEN_WIDTH = '768'

export const LANDING_PAGE_URL = 'https://cubyts.com/'

export const EXTERNAL_AUTH_TYPE = {
  FIGMA: 'FIGMA',
}

export const SUB_SYSTEM_NAME = {
  BYTS: 'BYTS',
  INDUCT: 'INDUCT',
  COMMON: 'COMMON',
}

export const executionModalList = [
  {
    label: "Scrum",
    value: 'scrum'
  },
  {
    label: "Waterfall",
    value: 'waterfall'
  },
  {
    label: "Kanban",
    value: 'kanban'
  },
  {
    label: "Others",
    value: 'others'
  }
]

export const teamSizeList = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '10+' },
]

export const roleList =  [
  { value: 'product_leadership', label: 'Product Leadership' },
  { value: 'head_of_product', label: 'Head of Product' },
  { value: 'product_owner', label: 'Product Owner' },
  { value: 'product_manager', label: 'Product Manager' },
  { value: 'scrum_master', label: 'Scrum Master' },
  { value: 'others', label: 'Others' }
];