import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Row, Col, Divider, Alert } from 'antd'
// import microsoftSignInIcon from '../../assets/icon/signIn/ms-symbollockup_signin_dark.svg'
import microsoftSignUpIcon from '../../assets/icon/onBoarding/ms-symbollockup_signup_light.svg'
import microsoftSignInIcon from '../../assets/icon/signIn/microsoftSigninDarkButton.svg'
import './SignIn.less'
import { getMicrosoftAuthURL } from '../../services/microsoftSignin'
import { authStatus, signInType } from '../../utils/constants'
import { useHistory } from 'react-router-dom'

const { Paragraph } = Typography

const MicrosoftSignIn = (props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    errorState,
    setErrorState,
    showDivider = true,
    fromSignUp,
    appliedCouponId,
  } = props

  useEffect(() => {
    // Get URL query params
    const urlSearchParams = new URLSearchParams(window.location.search)

    // Get the auth status and once you get it remove it. No clue why.
    const microsoftAuthStatus = urlSearchParams.get('msAuthStatus') || ''
    urlSearchParams.delete('msAuthStatus')

    // Get the auth message (ususally a death message) and once you get it remove it. No clue why.
    const errorMessage = urlSearchParams.get('msAuthMsg') || ''
    urlSearchParams.delete('msAuthMsg')

    // This is done to make sure that the query string is removed from the url without refreshing the page. But this does not work.
    window.history.pushState(
      {},
      document.title,
      `${window.location.pathname}?${urlSearchParams.toString()}`
    )

    if (
      microsoftAuthStatus &&
      microsoftAuthStatus.toUpperCase() === authStatus.failure
    ) {
      // This is our usual "janta" error message. This basically means that if there is an error apart from user not fount in case of signin and user already exists in case of signup, it throws the janta message.
      let errorMsg = t('common.errorMessage')

      // Process specific error from the url
      // THis is for signin if the user does not exist - these are errors coming from the backend
      if (
        !fromSignUp &&
        errorMessage &&
        errorMessage.toLowerCase() === 'usernotfound'
      ) {
        errorMsg = (
          <>
            {t('common.accountNotFound1')}
            <a onClick={() => history.push('/signup')}>{` ${t(
              'common.accountNotFound2'
            )}`}</a>
          </>
        )
      }
      // This is for signup - If the user already exists
      else if (
        fromSignUp &&
        errorMessage &&
        errorMessage.toLowerCase() === 'alreadyexists'
      ) {
        errorMsg = (
          <>
            {t('common.accountAlreadyExists1')}
            <a onClick={() => history.push('/signin')}>{` ${t(
              'common.accountAlreadyExists2'
            )}`}</a>
            {t('common.accountAlreadyExists3')}
          </>
        )
      } else if(fromSignUp && errorMessage && errorMessage.toLowerCase() === 'noncorporateemailid'){
        errorMsg =t('signUp.nonCorporateEmailIdErrorGoogle')
      }

      // set state
      setErrorState({
        type: signInType.microsoft,
        message: errorMsg,
      })
    }
  }, [])

  /**
   * This is the method to build the auth url which is used both for sign up and sign in and whether it is signup or signin is decided by the state which we pass and get back from microsoft APIs.
   */
  const getAuthURL = async () => {
    let currentRoute = window.location.href
    if (currentRoute && currentRoute.includes('o=true')) {
      currentRoute = currentRoute.replace('o=true', '')
    }
    getMicrosoftAuthURL(
      currentRoute,
      fromSignUp,
      fromSignUp && appliedCouponId ? appliedCouponId : '',
      fromSignUp && currentRoute && currentRoute.includes('q=assessment')
        ? true
        : false
    )
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.authCodeUrlResponse
        ) {
          window.location.href = response.data.authCodeUrlResponse
        } else {
          setErrorState({
            type: signInType.microsoft,
            message: t('signIn.microsoftSigninSignupIssue'),
          })
        }
      })
      .catch(() => {
        setErrorState({
          type: signInType.microsoft,
          message: t('signIn.microsoftSigninSignupIssue'),
        })
      })
  }

  // Component FE
  return (
    <div style={fromSignUp ? { marginTop: 24 } : {}}>
      <Row className='md:justify-start'>
        <Col className='cursor-pointer'>
          <img
            id="microsoftSignInButtonId"
            // className="microsoftSignInButton"
            src={fromSignUp ? microsoftSignUpIcon : microsoftSignInIcon}
            onClick={getAuthURL}
          />
        </Col>
      </Row>
    </div>
  )
}

MicrosoftSignIn.propTypes = {
  errorState: PropTypes.object,
  setErrorState: PropTypes.func,
  fromSignUp: PropTypes.bool,
  appliedCouponId: PropTypes.string,
}
MicrosoftSignIn.defaultProps = {
  errorState: {},
  setErrorState: () => {},
  fromSignUp: false,
  appliedCouponId: '',
}

export default MicrosoftSignIn
