import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const ReCaptchaTwo = forwardRef((props, ref) => {
  const recaptchTwoRef = useRef()
  useImperativeHandle(ref, () => ({
    generateToken: async () => {
      const token = await recaptchTwoRef.current.executeAsync()
      recaptchTwoRef.current.reset()
      return token
    },
    reset: () => {
      recaptchTwoRef.current.reset()
    },
  }))

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTHA_SITE_KEY}
      size="invisible"
      ref={recaptchTwoRef}
    />
  )
})

export default ReCaptchaTwo
