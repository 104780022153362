import { getJWTFromLocalStorage } from '../utils/common'

const AuthenticatedRoute = ({ children }) => {
  const jwtToken = getJWTFromLocalStorage()
  if (jwtToken) {
    return children
  } else {
    window.location.assign('/login')
  }
}

export default AuthenticatedRoute
