import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Tooltip, Row, Col, Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import './PasswordForm.less'
import PasswordToolTip from './passwordToolTip/PasswordToolTip'
import {
  passwordCaptialRuleRegex,
  passwordNumberRuleRegex,
  passwordRegex,
} from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import CommonConfirmPassword from '../../../components/CommonConfirmPassword/CommonConfirmPassword'
import { InfoCircleOutlined } from '@ant-design/icons';


const PasswordForm = (props) => {
  const history = useHistory()
  const [showPasswordToolTip, setShowPasswordToolTip] = useState(false)
  const [checked, setChecked] = useState(true)
  const [passwordRule, setPasswordRule] = useState({
    length: false,
    capitalLetter: false,
    number: false,
    // specialLetter: false,
  })
  const { saveNewPasswordCallback, isLoading, title, isTwoButton, subTitle } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const onFinish = (values) => {
    saveNewPasswordCallback(values.newPassword, checked)
  }

  const compareToNewPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('resetPassword.confirmPasswordNotMatchErrorMessage'))
    } else {
      callback()
    }
  }

  const passwordRegexCheck = (e) => {
    const value = e.target.value
    let lengthCheck = false,
      capitalLetter = false,
      number = false
    // specialLetter = false
    if (value && value.length > 7) {
      lengthCheck = true
    }

    if (passwordCaptialRuleRegex.test(value)) {
      capitalLetter = true
    }

    if (passwordNumberRuleRegex.test(value)) {
      number = true
    }

    // if (passwordSpecialLetterRuleRegex.test(value)) {
    //   specialLetter = true
    // }

    setPasswordRule({
      lengthCheck,
      capitalLetter,
      number,
      // specialLetter,
    })
  }

  const goToLoginPage = () => {
    history.push('/login')
  }

  return (
    <Row className="passwordFormContainer w-full" justify={'center'}>
      <Col  xs={24} sm={18} md={16} lg={16} xl={14} xxl={12}>
      <Row  className='mb-6'>
        <Col className="resetPasswordTitle titleText mb-2" span={24}>
          {title}
        </Col> 
        {subTitle?
        <Col span={24}>
          <span className='text-very-light text-sm '>{subTitle}</span>
        </Col>
        :
        <></>
        }
      </Row>
      <Form
        form={form}
        name="resetPasswordForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        size="large"
        style={{width:'100%'}}
      >
        {/* <Tooltip
          trigger={'hover'}
          visible={showPasswordToolTip}
          placement="right"
          color={'#FFF'}
          title={<PasswordToolTip {...passwordRule} />}
        >
          <Form.Item
            label={t('resetPassword.newPassword')}
            name="newPassword"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('resetPassword.newPasswordErrorMessage'),
              },
              {
                pattern: passwordRegex,
                message: t('resetPassword.newPasswordInvalidMessage'),
              },
            ]}
          >
          
            <Input.Password
              onFocus={() => setShowPasswordToolTip(true)}
              onBlur={() => setShowPasswordToolTip(false)}
              onChange={passwordRegexCheck}
            />
          </Form.Item>
        </Tooltip> */}
        {/* <Form.Item
          label={t('resetPassword.confirmPassword')}
          name="confirmPassword"
          validateTrigger={['onBlur']}
          //   className={'passwordInputWrapper'}
          rules={[
            {
              required: true,
              message: t('resetPassword.confirmPasswordErrorMessage'),
            },
            {
              validator: compareToNewPassword,
            },
          ]}
        >
          <Input.Password />
        </Form.Item> */}
        <CommonConfirmPassword form={form}/>
        <Form.Item name='isRemoveSessions' valuePropName="checked" >
          <Row className='gap-2 text-white mt-3' align={'middle'}>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)}/> Log-out of all other browser sessions
            <Tooltip title={'To enhance your account security, please log out of your account on all other devices after password reset.'}>
              <InfoCircleOutlined />
            </Tooltip>
          </Row>
        </Form.Item>

        <Form.Item>
          {isTwoButton ? (
            <Row className="buttonContainer" >
              <Button
                type="primary"
                htmlType="submit"
                className={'marginLeft'}
                loading={isLoading}
              >
                {t('resetPassword.savePass')}
              </Button>
            </Row>
          ) : (
            <Row className="buttonContainer" >
              <Button
                type="primary"
                htmlType="submit"
                
                className={'marginLeft'}
              >
                {t('resetPassword.savePassword')}
              </Button>
            </Row>
          )}
        </Form.Item>
      </Form>
      </Col>
    </Row>
  )
}

PasswordForm.propTypes = {
  isLoading: PropTypes.bool,
  saveNewPasswordCallback: PropTypes.func,
  title: PropTypes.string,
  isTwoButton: PropTypes.bool,
}
PasswordForm.defaultProps = {
  isLoading: false,
  title: '',
  saveNewPasswordCallback: () => {
    //empty function
  },
  isTwoButton: false,
}

export default PasswordForm
