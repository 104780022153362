import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Row, Col, Divider, Alert } from 'antd'
// import googleSignInIcon from '../../assets/icon/signIn/googleSignInButtonDark.svg'
import googleSignInIcon from '../../assets/icon/signIn/googleSignInDarkButton.svg'
import googleSignUpIcon from '../../assets/icon/onBoarding/googleSignUp.svg'
import './SignIn.less'
import { getGoogleAuthURL } from '../../services/googleSignIn'
import { authStatus, signInType } from '../../utils/constants'
import { useHistory } from 'react-router-dom'
const { Paragraph } = Typography

const GoogleSignIn = (props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    errorState,
    setErrorState,
    showDivider = true,
    fromSignUp,
    appliedCouponId,
  } = props

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const googleAuthStatus = urlSearchParams.get('authStatus') || ''
    urlSearchParams.delete('authStatus')
    const errorMessage = urlSearchParams.get('authMsg') || ''
    urlSearchParams.delete('authMsg')
    window.history.pushState(
      {},
      document.title,
      `${window.location.pathname}?${urlSearchParams.toString()}`
    )
    if (
      googleAuthStatus &&
      googleAuthStatus.toUpperCase() === authStatus.failure
    ) {
      let errorMsg = t('common.errorMessage')
      if (
        !fromSignUp &&
        errorMessage &&
        errorMessage.toLowerCase() === 'usernotfound'
      ) {
        errorMsg = (
          <>
            {t('common.accountNotFound1')}
            <a onClick={() => history.push('/signup')}>{` ${t(
              'common.accountNotFound2'
            )}`}</a>
          </>
        )
      } else if (
        fromSignUp &&
        errorMessage &&
        errorMessage.toLowerCase() === 'alreadyexists'
      ) {
        errorMsg = (
          <>
            {t('common.accountAlreadyExists1')}
            <a onClick={() => history.push('/signin')}>{` ${t(
              'common.accountAlreadyExists2'
            )}`}</a>
            {t('common.accountAlreadyExists3')}
          </>
        )
      } else if (
        fromSignUp &&
        errorMessage &&
        errorMessage.toLowerCase() === 'noncorporateemailid'
      ) {
        errorMsg = t('signUp.nonCorporateEmailIdErrorGoogle')
      }
      setErrorState({
        type: signInType.google,
        message: errorMsg,
      })
    }
  }, [])

  const getAuthURL = (e) => {
    e.preventDefault()
    if (errorState && errorState.type) {
      setErrorState({ type: '', message: '' })
    }
    let currentRoute = window.location.href
    if (currentRoute && currentRoute.includes('o=true')) {
      currentRoute = currentRoute.replace('o=true', '')
    }
    getGoogleAuthURL(
      currentRoute,
      fromSignUp,
      fromSignUp && appliedCouponId ? appliedCouponId : '',
      fromSignUp && currentRoute && currentRoute.includes('q=assessment')
        ? true
        : false
    )
      .then((respoonse) => {
        if (
          respoonse &&
          respoonse.status === 200 &&
          respoonse.data &&
          respoonse.data.url
        ) {
          window.location.href = respoonse.data.url
        } else {
          setErrorState({
            type: signInType.google,
            message: t('signIn.googleSignIssue'),
          })
        }
      })
      .catch(() => {
        setErrorState({
          type: signInType.google,
          message: t('signIn.googleSignIssue'),
        })
      })
  }

  return (
    <div style={fromSignUp ? { marginTop: 24 } : {}}>
      <Row className="md:justify-start">
        <Col className="cursor-pointer">
          {/* TODO: Add proper icon for sign UP. */}
          {/* <img id='googleSignInButtonId' src={fromSignUp ? googleSignUpIcon : googleSignInIcon} onClick={getAuthURL} /> */}
          <button className="gsi-material-button" onClick={getAuthURL}>
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  style={{display: 'block'}}
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  ></path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  ></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span className="gsi-material-button-contents">
                Sign in with Google
              </span>
            </div>
          </button>
        </Col>
      </Row>
      {/* {showDivider && <Divider className="orDivider">{t('signUp.orPlain')}</Divider>} */}
    </div>
  )
}

GoogleSignIn.propTypes = {
  errorState: PropTypes.object,
  setErrorState: PropTypes.func,
  fromSignUp: PropTypes.bool,
  appliedCouponId: PropTypes.string,
}
GoogleSignIn.defaultProps = {
  errorState: {},
  setErrorState: () => {
    //default function
  },
  fromSignUp: false,
  appliedCouponId: '',
}

export default GoogleSignIn
