import { useEffect, useState } from 'react'
import { SMALL_SCREEN_WIDTH } from './constants'

export const useViewPort = () => {
  const isMobileLogic = () => window.innerWidth <= SMALL_SCREEN_WIDTH
  const [isMobile, setIsMobile] = useState(isMobileLogic())

  const updateMedia = () => {
    setIsMobile(isMobileLogic())
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return { isMobileScreen: isMobile }
}
