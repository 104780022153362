import React from 'react'
import {Row, Col, Button} from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const RequestComplete = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const goToLoginPage = () => {
    history.push('/login')
  }

  return (
    <>
    <Row>
      <div className="text-white text-2xl mb-4">
        {t('forgotPassword.success.title')}
      </div>
      <Col span={24} className='mb-4'>
        <span className="text-very-light text-base font-normal">
          {t('forgotPassword.success.message')}
        </span>
      </Col>

      <Button type="primary" onClick={() => window.open('https://www.cubyts.com/','_self')} size="large">
        {t('forgotPassword.success.backBtn')}
      </Button>
      </Row>
    </>
  )
}
