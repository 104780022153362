import React, { useEffect } from 'react'
import CommonSkeleton from '../../components/CommonSkeleton/CommonSkeleton'
import { useHistory } from 'react-router-dom'
import { googleSignUp, setGoogleToken } from '../../services/googleSignIn'
import {
  parseJSONStringIntoObject,
  redirectToCubytsApps,
} from '../../utils/common'
import { get } from 'lodash'
import { authStatus } from '../../utils/constants'

const GoogleAuth = () => {
  const history = useHistory()

  useEffect(() => {
    const googleAuthCode = new URLSearchParams(window.location.search).get(
      'code'
    )
    let googleStateParam =
      new URLSearchParams(window.location.search).get('state') || ''
    const errorFromGoogle = new URLSearchParams(window.location.search).get(
      'error'
    )

    let {
      sourceUrl = '',
      isUserSignUp = false,
      workspaceId = '',
      tenantId = '',
      couponId = '',
      showAssessment = false
    } = parseJSONStringIntoObject(googleStateParam, true)

    if (googleAuthCode) {
      // clearJWTAndFlag()
      if (isUserSignUp === true || isUserSignUp === 'true') {
        signUpHanlder(googleAuthCode, sourceUrl, couponId, showAssessment)
      } else {
        setGoogleToken(googleAuthCode, workspaceId, tenantId)
          .then((response) => {
            if (response && response.status === 200) {
              if (response.data && response.data.authenticationToken) {
                localStorage.setItem('d', response.data.authenticationToken)
                localStorage.setItem('userId', response.data.userId)
              }
              const redirectionURL = `${sourceUrl}${
                sourceUrl.includes('?') ? '&' : '?'
              }authStatus=${authStatus.success}`
              // Added console log to debug google sign in stuck issue
              console.log('redirection url >>>>>', redirectionURL)
              if (redirectionURL.includes('closeWindowAfterSignIn')) {
                window.close()
              } else {
                window.location.assign(redirectionURL)
              }
            } else {
              handleError(response, sourceUrl)
            }
          })
          .catch((error) => {
            handleError(error.response, sourceUrl)
          })
      }
    } else if (errorFromGoogle) {
      handleError({ data: { error: { type: errorFromGoogle } } }, sourceUrl)
    }
  }, [])

  const handleError = (response, sourceUrl) => {
    const { type: errorType = '' } = get(response, 'data.error', {})
    sourceUrl = `${sourceUrl}${sourceUrl.includes('?') ? '&' : '?'}authStatus=${
      authStatus.failure
    }`
    if (errorType) {
      sourceUrl = `${sourceUrl}&authMsg=${errorType}`
    }
    window.location.assign(sourceUrl)
  }

  const signUpHanlder = (code = '', sourceUrl = '', couponId = '', showAssessment = false) => {
    googleSignUp({
      code,
      redirectionUrl: sourceUrl,
      ...(couponId && { couponId }),
      ...(showAssessment && { showAssessment })
    })
      .then((response) => {
        if (response && response.status === 200) {
          if (response.data && response.data.authenticationToken) {
            localStorage.setItem('d', response.data.authenticationToken)
            localStorage.setItem('userId', response.data.userId)
            let redirectionURL = new URL(sourceUrl)
            redirectionURL = new URLSearchParams(redirectionURL.search).get(
              'ru'
            )
            redirectToCubytsApps(
              response.data.authenticationToken,
              '',
              redirectionURL
            )
          }
        } else {
          handleError(response, sourceUrl)
        }
      })
      .catch((error) => {
        handleError(error.response, sourceUrl)
      })
  }
  return <CommonSkeleton />
}

GoogleAuth.propTypes = {}

export default GoogleAuth
