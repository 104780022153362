import { Col, Row, message } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { resetUserPassword, validateJWTTokenService } from '../../services/user'
import { signinStep } from '../../utils/constants'
import PasswordForm from './passwordForm/PasswordForm'
import SignInForm from './SignInForm'
import UserDetailsForm from './userDetailsForm/UserDetailsForm'
// import LHSRHSWrapper from '../../components/backgroundWrapper/LHSRHSWrapper'
import CommonSkeleton from '../../components/CommonSkeleton/CommonSkeleton'
import { redirectToCubytsApps } from '../../utils/common'
import CommonSignUpBackground from './../../components/CommonSignUpBackground/CommonSignUpBackground'
import PrivacyPolicySection from './PrivacyPolicySection'
import './SignIn.less'
import RequestAccessForm from './RequestAccessForm'

const RequestAccess = (props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [currentSignInSetup, setCurrentSignInSetup] = useState(
    signinStep.signIn
  )
  const [userToken, setUserToken] = useState('')
  const [isJWTVerifying, setIsJWTVerifying] = useState(true)
  const [size, setSize] = useState([0, 0])

  const clearJWTAndFlag = () => {
    localStorage.setItem('d', '')
    localStorage.setItem('userId', '')
    setIsJWTVerifying(false)
  }
  useEffect(() => {
    const isUserLogout =
      new URLSearchParams(window.location.search).get('o') || false
    if (!isUserLogout) {
      const jwt = localStorage.getItem('d')
      if (jwt) {
        validateJWTTokenService()
          .then((response) => {
            if (response && response.status === 200) {
              redirectToCubytsApps(jwt, props.oldPath)
            } else {
              clearJWTAndFlag()
            }
          })
          .catch(() => {
            clearJWTAndFlag()
          })
      } else {
        setIsJWTVerifying(false)
      }
    } else {
      clearJWTAndFlag()
    }
  }, [])
  const saveNewPassword = (password) => {
    setIsLoading(true)

    resetUserPassword({ password: password, isForcePassword: true }, userToken)
      .then((result) => {
        if (result && result.status === 200) {
          setCurrentSignInSetup(signinStep.userDetails)
        } else {
          message.error(t('common.errorMessage'))
        }
      })
      .catch(() => {
        message.error(t('common.errorMessage'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      {isJWTVerifying ? (
        <CommonSkeleton />
      ) : (
        <CommonSignUpBackground signInFlow={true}>
          <>
            {currentSignInSetup === signinStep.signIn && (
              <>
                <Helmet>
                  <title>{t('browserTitle.earlyAccess')}</title>
                </Helmet>
                <Row  align="middle" className='w-full' justify={'center'}>
                  <Col xs={24} sm={24} md={15} lg={16} xl={16} xxl={16} className='md:m-32'>
                    <RequestAccessForm
                      setUserToken={setUserToken}
                      setCurrentSignInSetup={setCurrentSignInSetup}
                      oldPath={props.oldPath}
                    />
                    <PrivacyPolicySection />
                  </Col>
                </Row>
              </>
            )}
            {currentSignInSetup === signinStep.password && (
              <>
                <PasswordForm
                  saveNewPasswordCallback={saveNewPassword}
                  isLoading={isLoading}
                  title={t('resetPassword.title')}
                />
              </>
            )}
            {currentSignInSetup === signinStep.userDetails && (
              <UserDetailsForm userToken={userToken} oldPath={props.oldPath} />
            )}
          </>
        </CommonSignUpBackground>
      )}
    </>
  )
}

RequestAccess.propTypes = {
  oldPath: PropTypes.string,
}

RequestAccess.defaultProps = {
  oldPath: '',
}

export default RequestAccess
