import { Button, message, Typography } from 'antd'
import { useState } from 'react'
import CommonStepsHeader from '../../components/CommonOnboardingSteps/CommonStepsHeader'
import { updateAuthTokenForChallengeRequest } from '../../services/user'
import integrationSuccessImage from '../../assets/icon/externalAuth/cubyts-to-figma.svg'
import { useTranslation } from 'react-i18next'

const getCodeChallengeFromParam = () => {
  return new URLSearchParams(window.location.search).get('challenge') || ''
}

const AuthenticateFigma = () => {
  const [figmaAuthSuccess, setFigmaAuthSuccess] = useState(false)
  const { t } = useTranslation()

  const updateAuthTokenForChallenge = async () => {
    try {
      const codeChallenge = getCodeChallengeFromParam()

      if (codeChallenge) {
        const response = await updateAuthTokenForChallengeRequest({
          codeChallenge,
        })
        if (response && response.data && response.data.codeChallenge) {
          setFigmaAuthSuccess(true)
        }
        if (response && response.data && response.data.sessionExpired) {
          message.error(t('common.pkceSessionExpired'))
        }
      }
    } catch (error) {
      message.error(t('common.errorMessage'))
    }
  }

  return (
    <div>
      <CommonStepsHeader />
      <div style={{ textAlign: 'center', margin: 'auto', marginTop: '12%' }}>
        {!figmaAuthSuccess ? (
          <>
            <Typography.Title
              style={{ fontSize: '32px', marginBottom: '20px' }}
            >
              {t('authenticateFigma.grantScreenTitle')}
            </Typography.Title>
            <Typography.Paragraph
              style={{ fontSize: '16px', marginBottom: '20px' }}
            >
              {t('authenticateFigma.grantScreenSubTitle')}
            </Typography.Paragraph>
            <Button
              type="primary"
              style={{ height: '48px', fontSize: '16px' }}
              onClick={updateAuthTokenForChallenge}
            >
              {t('authenticateFigma.grantAccessButton')}
            </Button>
          </>
        ) : (
          <>
            <img src={integrationSuccessImage} alt={'Intgeration Success'} />
            <Typography.Title
              style={{
                fontSize: '32px',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              {t('authenticateFigma.authSuccessTitle')}
            </Typography.Title>
            <Typography.Paragraph style={{ fontSize: '16px' }}>
              {t('authenticateFigma.authSuccessSubTitle')}
            </Typography.Paragraph>
          </>
        )}
      </div>
    </div>
  )
}

export default AuthenticateFigma
