import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'
import './LHSCard.less'
import tickMarkIcon from '../../assets/icon/common/tickMark.svg'
const { Text } = Typography
const LHSCard = (props) => {
  const { icon, textsArray, iconClassName } = props
  return (
    <div className={'lhsCardContainer'}>
      {icon && <img className={iconClassName ? iconClassName : ''} alt='' src={icon} />}
      {!!textsArray.length && <div className={'textSection'}>
        {textsArray.map((text) => (
          <div>
            <div>
              <img alt='' src={tickMarkIcon} />
            </div>
            <div>
              <Text>{text}</Text>
            </div>
          </div>
        ))}
      </div>}
    </div>
  )
}

LHSCard.propTypes = {
  icon: PropTypes.string,
  textsArray: PropTypes.array,
  iconClassName: PropTypes.string
}

LHSCard.defaultProps = {
  icon: null,
  textsArray: [],
  iconClassName: ''
}
export default LHSCard
