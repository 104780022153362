import React, {useState} from 'react'
import './CommonSignUpBackground.less'
import PropTypes from 'prop-types'
import BackgroundWrapper from '../backgroundWrapper/BackgroundWrapper'
import { Row, Col } from 'antd'
import { useViewPort } from '../../utils/hooks'

const CommonSignUpBackground = ({ span, children, signInFlow }) => {
  const {isMobileScreen} = useViewPort() 

  return (
    <BackgroundWrapper signInFlow={signInFlow} >
      <Row align="middle" className={`${!isMobileScreen ? 'rhsStyle' : 'rhsStyleMobile'} bg-app`}>
        <Col span={24}>
          {children}
        </Col>
        {/* {!isMobileScreen && <Col span={1}></Col>} */}
      </Row>
    </BackgroundWrapper>
  )
}

export default CommonSignUpBackground

CommonSignUpBackground.propTypes={
    span : PropTypes.number, 
    signInFlow : PropTypes.bool, 
    children: PropTypes.node
}

CommonSignUpBackground.defaultProps={
    span : 6, 
    signInFlow : false, 
    children: <></>
}
