import { PageHeader } from '@ant-design/pro-layout'
import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import cubytsLogo from '../../assets/icon/common/cubyts-whole-light-no-bg.svg'
import { LANDING_PAGE_URL } from '../../utils/constants'
import { useViewPort } from '../../utils/hooks'
import './BackgroundWrapper.less'

const { Paragraph, Text } = Typography
const BackgroundWrapper = ({ signInFlow = true, children }) => {
  const { t } = useTranslation()
  const { isMobileScreen } = useViewPort()
  return (
    <>
      {!isMobileScreen ? (
        <Row
          className={
            signInFlow ? 'backgroundSignInWrapper' : 'backgroundSignUpWrapper'
          }
        >
          <Col
            span={8}
            className={signInFlow ? 'signInBannerImg' : 'signUpBannerImg'}
          >
            <PageHeader
              title={
                <img
                  alt="logo"
                  src={cubytsLogo}
                  onClick={() => {
                    window.location.href = LANDING_PAGE_URL
                  }}
                  style={{ cursor: 'pointer' }}
                />
              }
              className="pl-10 pt-10"
            ></PageHeader>
            <div className="flex flex-col pt-24 items-center">
              <div className="w-full ml-20  hidden">
                <div className="text-4xl font-extrabold text-white">
                  {t('backgroundWrapper.heading1')}
                </div>
                <div className="text-4xl font-extrabold text-white">
                  {t('backgroundWrapper.heading2')}
                </div>
                <div className="text-4xl font-extrabold text-white">
                  {t('backgroundWrapper.heading3')}
                </div>
              </div>
              <div className="text-base font-normal text-white pt-6 w-72">
                {t('backgroundWrapper.description10')}
              </div>
            </div>
            <Row>
              {/* <img
                alt="logo"
                src={signInFlow? signInIconLogo : signUpIconLogo}
                style={{ width: '100%', height: 'auto', objectFit: 'contain', marginTop: -20 }}
              /> */}
              <div></div>
              {!signInFlow && (
                <Row
                  style={{
                    flexDirection: 'column',
                    padding: '0px 50px 0px 50px',
                    marginTop: -20,
                    justifyContent: 'flex-end',
                  }}
                >
                  <br />
                  <br />

                  <Text
                    style={{ fontSize: 14, fontWeight: 500, color: '#FFFFFF' }}
                  >
                    {t('backgroundWrapper.description8')}
                  </Text>
                  <Text
                    style={{ fontSize: 14, fontWeight: 500, color: '#FFFFFF' }}
                  >
                    {t('backgroundWrapper.description9')}
                  </Text>
                </Row>
              )}
            </Row>
          </Col>
          <Col span={16} className="bg-app">
            {children}
          </Col>
        </Row>
      ) : (
        <Row
          className={
            signInFlow ? 'backgroundSignInWrapper' : 'backgroundSignUpWrapper'
          }
        >
          <Col span={24} className="bg-app">
            <PageHeader
              title={<img alt="logo" src={cubytsLogo} />}
            ></PageHeader>
            <Row justify="center">
              {!signInFlow ? (
                <Row
                  style={{
                    flexDirection: 'column',
                    padding: '40px 50px',
                  }}
                >
                  {children}
                </Row>
              ) : (
                <Row
                  style={{
                    flexDirection: 'column',
                    padding: '50px 50px 0px 50px',
                  }}
                >
                  {children}
                </Row>
              )}
              {/* <img
                alt="logo"
                src={signInFlow? signInIconLogo : signUpIconLogo}
                style={{
                  maxWidth: '-webkit-fill-available',
                  marginTop: -20,
                }}
              /> */}
            </Row>
          </Col>
          {/* <Col span={16} style={{ background: '#FAFBFC' }}>
            
          </Col> */}
        </Row>
      )}
    </>
  )
}

export default BackgroundWrapper
