import React, { useState } from 'react'
import uploadIcon from '../../assets/icon/common/imageUpload.svg'
import styles from './UploadImage.less'
import { useTranslation } from 'react-i18next'
import { ALLOWED_IMAGE_FILE_TYPES_FOR_USER_PROFILE } from '../../utils/constants'
import { Upload, Avatar } from 'antd'
import ImgCrop from 'antd-img-crop'
import 'antd/es/modal/style';
import 'antd/es/slider/style';
import Icon from '@ant-design/icons'

const UploadImage = ({ onChange }) => {
  const { t } = useTranslation()
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)

  const handleNewlyAddedFile = () => {
    onChange(file)
  }

  const uploadTriggerProp = {
    showUploadList: false,
    beforeUpload: (uploadedFile) => {
      setPreview(URL.createObjectURL(new Blob([uploadedFile])))
      setFile(uploadedFile)
      return false
    },
    multiple: false,
    onChange: handleNewlyAddedFile,
    accept: ALLOWED_IMAGE_FILE_TYPES_FOR_USER_PROFILE.join(','),
  }

  return (
    <div className="uploadImageContainer">
      <Avatar
        size={80}
        icon={
          <Icon
            style={{ verticalAlign: '0.5em' }}
            component={() => <img src={uploadIcon} />}
          />
        }
        src={preview}
      ></Avatar>
      <ImgCrop rotate shape="round" quality={1} className={styles.cropModal}>
        <Upload {...uploadTriggerProp}>
          <a>{t('common.uploadPicture')}</a>
        </Upload>
      </ImgCrop>
    </div>
  )
}

UploadImage.propTypes = {}

export default UploadImage
