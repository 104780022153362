import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import './SignIn.less'
const Paragraph = Typography

const PrivacyPolicySection = ({ showAgreements }) => {
  const { t } = useTranslation()
  return (
    <>
      {!showAgreements ? (
        <Row justify="start" className="privacyPolicySectionContainer">
          <Col>
            <Paragraph>
              <a
                href="https://cubyts.com/compliance/privacy-policy"
                target="_blank"
              >
                {t('signIn.privacy')}
              </a>
              <span className="horizontalSeparator">|</span>
              <a href="https://cubyts.com/compliance/eula" target="_blank">
                {t('signIn.userLicenceAgreement')}
              </a>
            </Paragraph>
          </Col>
        </Row>
      ) : (
        <Row justify="center" className="privacyPolicySectionContainer agreementSectionContainer">
          <Col>
            <Paragraph>
              {t('signIn.signupAgreement')}
              <a href="https://cubyts.com/compliance/privacy-policy" target="_blank">
                {` ${t('signIn.cubytsPrivacy')}`}
              </a>
              {t('signIn.and')}
              <a href="https://cubyts.com/compliance/saas" target="_blank">
                {t('signIn.saasAgreement')}
              </a>
            </Paragraph>
          </Col>
        </Row>
      )}
    </>
  )
}

PrivacyPolicySection.propTypes = {
  showAgreements: PropTypes.bool,
}
PrivacyPolicySection.defaultProps = {
  showAgreements: false,
}

export default PrivacyPolicySection
