import axios from '../utils/axios'
import { SUB_SYSTEM_NAME } from '../utils/constants'
import moment from 'moment'

export const postEarlyAccessUser = (values, token) =>
  axios({
    apiVersion: '/v2',
    url: `/earlyaccess`,
    subSystemName: SUB_SYSTEM_NAME.INDUCT,
    method: 'POST',
    data: {
      ...values,
    },
    headers: {
      'X-Recaptcha': token,
      'X-Timezone': moment.tz.guess(),
    },
  })
