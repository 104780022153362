const data = {
  //sigin in
  common: {
    firstName: 'First Name',
    lastName: 'Last Name ',
    emailId: 'Email address',
    password: 'Password',
    signInText: 'Sign In',
    signUpText: 'Sign up',
    errorMessage: 'Something went wrong! Please try after sometime.',
    pkceSessionExpired: 'Session expired! Please try again.',
    forgotPassword: 'Forgot Password?',
    uploadPicture: 'Upload picture',
    tryAgain: 'Try Again',
    accountNotFound1:
      "Sorry, we couldn't find your account. Try signing in from a different account or create a new account using ",
    accountNotFound2: 'Sign Up.',
    accountAlreadyExists: 'This account already exists. Please try from a different account ',
    accountAlreadyExists1: 'This account already exists. Please ',
    accountAlreadyExists2: 'Sign In',
    accountAlreadyExists3:
      ' to continue or try signing up from a different account.',
  },
  signIn: {
    headerRight: {
      title1: 'Having trouble signing up?',
      title2: 'Get help',
    },
    LHS: {
      message1: 'Scale design 2x faster',
      message2: 'Define and track design metrics',
      message3: '(Re)use Design Artifacts, Templates, \nLibraries & Standards',
    },
    form: {
      title: 'Sign in',
      subtitle1: {
        message1: 'Please use the credentials sent to your email address.',
      },
      subtitle2: {
        message1: 'Don’t have an account yet?',
        message2: 'Request for a demo',
      },
      noEmailError: 'Please enter your email address.',
      noPasswordError: 'Please enter your password.',
      invalidEmailError: 'Invalid email address',
      validWorkEmail: 'Please provide a valid work email address',
      invalidNumberError: 'Enter only numbers',
      invalidUrlError: 'Invalid URL',
      emailNotFound: 'Email ID entered is invalid',
      userNotFound: `Look like this account doesn't exist. Please Sign-up to create an account.`,
      passwordNotMatch: 'Password entered is invalid.',
      recaptchError: 'Recaptcha verification failed.',
      userNamePasswordIncorrect: {
        message1: 'The username or password is not correct.',
        message2: 'Did you ',
        message3: 'forget your password?',
      },
    },
    privacy: 'Privacy ',
    cubytsPrivacy: 'Privacy policy ',
    userLicenceAgreement: ' End User’s Licence Agreement',
    saasAgreement: ' SaaS Agreement ',
    signupAgreement: 'By signing up, I agree to the Cubyts',
    and: 'and',
    or: 'Sign in with',
    orPlain: 'OR',
    continueWithGoogle: 'Sign in with Google',
    googleSignIssue:
      'We are unable to proceed with Google Sign in. Please try an alternate method to Log in.',
    microsoftSigninSignupIssue: 'We are unable to proceed with Microsoft signin/signup. Please try an alternate method to Log in.'
  },
  requestAccess: {
    form: {
      title: "Request for a demo",
      subtitle1: "Please fill the form to proceed with your demo request.",
      name: "Your Name",
      firstName: "First name",
      lastName: "Last name",
      nameHolder: "Type your name",
      firstNameHolder: "First name",
      emptyFirstNameError: 'Please enter your first name',
      lastNameHolder: "Last name",
      emptyLastNameError: 'Please enter your last name',
      email: "Work email",
      emailHolder: "name@example.com",
      emptyWorkEmailError: 'Please enter your work email',
      role: "Your role",
      roleHolder: "Please select a role",
      emptyRoleError: 'Please enter your role',
      company: {
        name: "Company name",
        nameHolder: "Company name",
        emptyNameError: 'Please enter your company name',
        url: "Company website",
        urlHolder: "https://www.example.com",
        emptyWebsiteError: 'Please enter your company website',
        executionModel: "Execution model of your team",
        executionModelHolder: "Select type of execution followed ",
        emptyExecutionModelError: 'Please enter your execution model',
        teamSize: "Number of squads/scrum teams",
        teamSizeHolder: "Select",
        emptyTeamSizeError: 'Please enter your team size',
      },
      message: "Any message for us?",
      messageHolder: "Enter your message here",
      submit: "Submit"
    }
  },
  resetPassword: {
    title: 'Reset your password',
    setNewPassword: 'Please reset your password',
    setNewPasswordSubtitle: 'For the safety of your account, please create a new password of your choice. It should at least have 8 characters with combination of uppercase letters, lowercase letters, numbers, and special characters.',
    newPassword: 'Password',
    confirmPassword: 'Confirm Password',
    savePassword: 'Save New Password',
    savePass: 'Save new Password',
    newPasswordErrorMessage: 'Please enter your new password',
    newPasswordInvalidMessage:
      'Password must be at least 8 characters with 1 upper case, 1 number',
    confirmPasswordErrorMessage: 'Please enter the password again',
    confirmPasswordNotMatchErrorMessage: "The passwords don't match",
    passworRule: {
      lengthRule: 'Minimum 8 characters',
      capitalLetter: 'Atleast 1 capital letter (A-Z)',
      specialLetter: 'Atleast 1 special character (# ! @ $ % & *)',
      number: 'Atleast 1 number (0-9)',
    },
  },
  userDetails: {
    title: 'Please help us with a few more details.',
    firstName: 'First Name',
    lastName: 'Last Name',
    changePicture: 'Change Picture',
    roleTitle: 'What is your function',
    workspaceName: 'Give your workspace a name',
    otherUserRole: 'Please specify your function',
    firstNameError: 'Please enter your first name',
    lastNameError: 'Please enter your last name',
    roleTitleError: 'Please enter your function',
    workspaceNameError: 'Please enter you workspace name',
    letsStart: 'Let’s Start',
  },
  forgotPassword: {
    subtitle: 'Please enter the email address registered on cubyts to receive your password reset information.',
    enterEmail: 'Email address',
    checkMail: 'Reset information sent to your email',
    checkMailExist: {
      message1: `If there's a Cubyts account linked to  `,
      message2:
        ',you will receive instructions for resetting your password in your email. Please check your inbox to proceed with the password reset.',
    },
    success: {
      title: "Request for early access raised successfully",
      message: "Thank you for showing interest in Cubyts. We truly appreciate your interest and will be in touch shortly to update you on the status of your request.",
      backBtn: "Back to site"
    },
    retrunToLogin: 'Back to Login',
    sendLink: 'Request for reset link',
    linkExpired: 'Sorry, this link has expired!',
    returnToLoginMessage:
      'Please reach out to the owner to generate a new link',
    resetPasswordSuccessfully: 'Password reset successfully!',
    resetPasswordMessage:
      'You can log in to your account using your new password.',
  },
  browserTitle: {
    login: 'Log in | Cubyts',
    resetPassword: 'Reset Password - Log In | Cubyts',
    welcomePage: 'Welcome to Cubyts | Cubyts',
    signup: 'Sign Up | Cubyts',
    emailVerification: 'Email Verification | Cubyts',
    earlyAccess: 'Request Demo | Cubyts'
  },
  signUp: {
    form: {
      title: 'Sign up for Free',
      subtitle: {
        message1: 'Already a user?',
        message2: ' Sign in',
      },
      noFirstName: 'Please enter first name.',
      noLastName: 'Please enter last name.',
    },
    nonCorporateEmailId: 'Please enter a valid work email address',
    nonCorporateEmailIdErrorGoogle: 'Please use a valid work email address',
    or: 'or sign up with your work email',
    orPlain: 'or',
    emailId: 'Work Email Address',
    alreadyExistError: 'Sorry, this email is already registered',
    coupon: {
      haveCoupon: 'Have a promo code?',
      promoCodeApplied: 'Promo code applied successfully.',
      viewDetails: 'View details',
    },
    proceed: 'Proceed',
  },
  emailVerification: {
    expiredTitle: 'Email verification link expired!',
    invalidTitle: 'Sign-in to verify your email',
    title: 'Verify your email address',
    verifiedTitle: 'This email id has already been verified',
    invalidMessage: `Your email verification is pending. Sign-in to verify your email-id and continue using the platform`,
    verifyMessage:
      'A verification link has been sent to your registered email address. Just click on the link to complete your signup.',
    expiredMessage:
      'Looks like the email verification link has expired. Not to worry, we can send the link again.',
    verifiedMessage:
      'We already have a verfied account with this email id. Please continue to use the platform.',
    mailNotFound: "Couldn't find the link?",
    resend: 'Request for a new link',
    resendButton: 'Resend Verification Link',
    resendSuccess: 'Link sent again',
    resendFailure: 'Failed to send the link',
    goToWorkspaceButton: 'Go to my Workspace',
    api: {
      verification:
        "Looks like your verification link is invalid or wasn't copied correctly. Not to worry, we can send the link again.",
    },
  },
  carousal: {
    heading1: 'Streamline your DesignOps',
    content1:
      'Align your business strategy and design strategy seamlessly with visions, goals and linked design projects.',
    heading2: 'Streamline your DesignOps',
    content2:
      'Create industry grade Design Processes as Design Roadmap for your teams to follow.',
    heading3: 'Streamline your DesignOps',
    content3:
      'Enable a culture of deep design-centric collaboration with transparency in processes, ownership of activities  and easy review cycles.',
    heading4: 'Streamline your DesignOps',
    content4:
      'Build an intelligent repository as a single source of wisdom for the entire organisation to consume.',
  },
  onBoardingSteps: {
    next: 'Next',
    done: 'Done',
    optional: '(Optional)',
    step1: {
      title: 'Tell us a bit about yourself',
      content1: 'I am a',
      content2: 'in',
      content3: 'industry working in a team of',
      content4: 'employees',
    },
    step2: {
      title: 'Give your workspace a name',
    },
  },
  couponSection: {
    label: 'Apply Cubyts Credits',
    placeholder: 'Enter Code',
    PromoCode: 'Promo Code',
    RemoveCode: 'Remove Code',
    okay: 'Okay',
    couponDescription: 'Looks like you bagged a coupon code!',
    apply: 'Apply',
    successMsg: 'Coupon applied successfully!',
  },
  backgroundWrapper: {
    description1: 'Design Management',
    description1_1: 'Done Your Way!',
    description2: 'No credit card required',
    description3: 'With Cubyts, you can:',
    description4: 'Plan with Zero Hassle',
    description5: 'Prioritize Team Wellness',
    description6: 'Cultivate Design Culture',
    description7: 'Build Design Memory',
    description8: 'Need help?',
    description9: 'Write to us at support@cubyts.com',
    heading1: 'Cut product debts in half',
    heading2: '',
    heading3: 'with Cubyts',
    description10: '',
  },
  authenticateFigma: {
    grantScreenTitle: 'Grant access to Cubyts for Figma Plugin',
    grantScreenSubTitle:
      'Grant access to use the Cubyts integration to check in your designs.',
    grantAccessButton: 'Grant Access',
    authSuccessTitle: 'Congratulations! You’ve successfully logged in to the Cubyts.',
    authSuccessSubTitle: 'Go to figma to check in your designs through the Cubyts widget.',
  },
}
export default data
