import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Typography, Input, Button, Alert, Row, Col, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { emailRegex, signinStep, signInType } from '../../utils/constants'
import { authenticateUser } from '../../services/user'
import { get } from 'lodash'
import {
  redirectToCubytsApps,
  storeUserIdInLocalStorage,
} from '../../utils/common'
import './SignIn.less'
import ReCaptchaTwo from '../../components/recaptcha/ReCaptchaTwo'
import { Link, useHistory } from 'react-router-dom'
import GoogleSignIn from './GoogleSignIn'
import MicrosoftSignIn from './MicrosoftSignIn'
const { Text, Paragraph } = Typography

const userNamePasswordInvalidErrorConstant = 'USERNAME_PASSWORD_INVALID_ERROR'

const getFilteredSearchParam = (searchParams) => {
  const searchParamInstance = new URLSearchParams(searchParams)
  searchParamInstance.delete('challenge')
  searchParamInstance.delete('external_authentication')
  return `?${searchParamInstance.toString()}`
}

const SignInForm = (props) => {
  const { oldPath, setCurrentSignInSetup, setUserToken } = props
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [errorState, setErrorState] = useState({ type: '', message: '' })
  const recaptchRef = useRef()
  let history = useHistory()

  useEffect(() => {
    if (oldPath) {
      let userEmailFromURL = new URLSearchParams(oldPath).get('ue') || ''

      if (userEmailFromURL) {
        userEmailFromURL = Buffer.from(userEmailFromURL, 'base64').toString()
        form.setFieldsValue({
          emailId: userEmailFromURL,
        })
      }
    }
  }, [])

  const onFinish = async (values) => {
    const { emailId, password } = values

    setIsLoading(true)
    if (errorState && errorState.type) {
      setErrorState({ type: '', message: '' })
    }
    const token = await recaptchRef.current.generateToken()
    authenticateUser(emailId, password, token)
      .then((result) => {
        const {
          userId,
          authenticationToken,
          loginCount = 0,
          onboarding,
        } = get(result, 'data', {})
        if (userId) {
          if (onboarding && onboarding.completedSteps === 1) {
            storeUserIdInLocalStorage(userId)
            history.push('/emailverification?rt=true')
            return
          }

          if (loginCount === 1) {
            setUserToken(authenticationToken)
            setCurrentSignInSetup(signinStep.password)
          } else {
            redirectToCubytsApps(authenticationToken, oldPath)
          }
          storeUserIdInLocalStorage(userId)
        } else {
          setErrorState({
            type: signInType.cubyts,
            message: userNamePasswordInvalidErrorConstant,
          })
        }
      })
      .catch((error) => {
        const { code: errorCode, type: errorType } = get(
          error,
          'response.data.error',
          {}
        )

        if (errorCode === 2200) {
          setErrorState({
            type: signInType.cubyts,
            message: t('signIn.form.recaptchError'),
          })
        } else if (errorType === 'unauthorised' || errorType === 'incorrectPassword') {
          setErrorState({
            type: signInType.cubyts,
            message: userNamePasswordInvalidErrorConstant,
          })
        } else if (errorCode === 1011) {
          setErrorState({type: signInType.cubyts , message: t('signIn.form.userNotFound')})
        }
        else {
          setErrorState({
            type: signInType.cubyts,
            message: t('common.errorMessage'),
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <div className="signInFormContainer">
      <ReCaptchaTwo ref={recaptchRef} />
      <div className="signInTitle">{t('signIn.form.title')}</div>
      {/* <div className="text-very-light">
        {t('signIn.form.subtitle1.message1')}
      </div> */}
      <Paragraph className="subtitle ">
        <span>{`${t('signIn.form.subtitle2.message1')} `}</span>
        <Link
          className="text-primary underline"
          to={`/request_access${getFilteredSearchParam(
            history.location.search
          )}`}
        >{`${t('signIn.form.subtitle2.message2')}`}</Link>
      </Paragraph>

      <Form
        name="signInForm"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        size="large"
        form={form}
      >
        <Form.Item
          label={t('common.emailId')}
          name="emailId"
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              message: t('signIn.form.noEmailError'),
            },
            {
              pattern: emailRegex,
              message: t('signIn.form.invalidEmailError'),
            },
          ]}
        >
          <Input
            onChange={(e) =>
              form.setFieldsValue({
                ...form.getFieldsValue(),
                emailId: e.target.value.trim(),
              })
            }
            autoComplete="off"
            className='-mt-3'
          />
        </Form.Item>

        <Form.Item
          label={
            <div className={'passwordLabel'}>
              <span>{t('common.password')}</span>
              <Link
                to={`/forgotpassword${getFilteredSearchParam(
                  history.location.search
                )}`}
                className="text-white cursor-pointer hover:underline"
              >
                {t('common.forgotPassword')}
              </Link>
            </div>
          }
          name="password"
          className={'passwordInputWrapper'}
          rules={[
            {
              required: true,
              message: t('signIn.form.noPasswordError'),
            },
          ]}
        >
          <Input.Password autoComplete="off" className='-mt-3' />
        </Form.Item>

        <Form.Item className="justify-start flex">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            block
            className={'signInButton'}
          >
            {t('common.signInText')}
          </Button>
        </Form.Item>

        <Divider className="orDivider">{t('signIn.orPlain')}</Divider>

        {/* <Row className="mb-3">
          <Col span={24}>
            <span className="text-extra-light font-bold text-sm flex justify-center">
              {t('signIn.or')}
            </span>
          </Col>
        </Row> */}
        <Row gutter={[12,12]} className='xs:flex-col sm:flex-col md:flex-row'>
          <Col>
            <GoogleSignIn
              errorState={errorState}
              setErrorState={setErrorState}
            />
          </Col>
          <Col>
            <MicrosoftSignIn
              errorState={errorState}
              setErrorState={setErrorState}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {errorState &&
              (errorState.type === signInType.microsoft ||
                errorState.type === signInType.google) && (
                <Alert
                  style={{ marginBottom: '10px', marginTop: '0px' }}
                  message={<Paragraph>{errorState.message}</Paragraph>}
                  type="error"
                />
              )}
          </Col>
        </Row>

        {errorState && errorState.type === signInType.cubyts && (
          <Alert
            className='mt-4'   
            message={
              errorState.message === userNamePasswordInvalidErrorConstant ? (
                <div>
                  <Text>
                    {t('signIn.form.userNamePasswordIncorrect.message1')}
                  </Text>
                  <Paragraph>
                    {t('signIn.form.userNamePasswordIncorrect.message2')}
                    <Link
                      to={`/forgotpassword${getFilteredSearchParam(
                        history.location.search
                      )}`}
                    >
                      {t('signIn.form.userNamePasswordIncorrect.message3')}
                    </Link>
                  </Paragraph>
                </div>
              ) : (
                <Paragraph>{errorState.message}</Paragraph>
              )
            }
            type="error"
          />
        )}
      </Form>
    </div>
  )
}

SignInForm.propTypes = {
  oldPath: PropTypes.string,
  setCurrentSignInSetup: PropTypes.func,
  setUserToken: PropTypes.func,
}

SignInForm.defaultProps = {
  oldPath: '',
  setCurrentSignInSetup: () => {
    //empty function
  },
  setUserToken: () => {
    //empty function
  },
}
export default SignInForm
