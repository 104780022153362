import axios from 'axios'
import { SUB_SYSTEM_NAME } from './constants'

const currentVersion = process.env.REACT_APP_API_VERSION
const apiPrefix = process.env.REACT_APP_API_PREFIX
const bytsApiPrefix = process.env.REACT_APP_BYTS_API_PREFIX
const commonApiPrefix = process.env.REACT_APP_COMMON_API_PREFIX
const hostName = process.env.REACT_APP_API_HOST
const bytsHostName = process.env.REACT_APP_BYTS_API_HOST
const commonHostName = process.env.REACT_APP_COMMON_API_HOST
const apiTimeout = process.env.REACT_APP_API_TIMEOUT

// axios.defaults.baseURL = `${hostName}${apiPrefix}${currentVersion}`
axios.defaults.headers.common['Content-Type'] = 'application/json'

export function getBaseUrl(
  subSystemName = SUB_SYSTEM_NAME.INDUCT,
  apiVersion = currentVersion
) {
  switch (subSystemName) {
    case SUB_SYSTEM_NAME.INDUCT:
      return `${hostName}${apiPrefix}${apiVersion}`

    case SUB_SYSTEM_NAME.BYTS:
      return `${bytsHostName}${bytsApiPrefix}${apiVersion}`

    case SUB_SYSTEM_NAME.COMMON:
      return `${commonHostName}${commonApiPrefix}${apiVersion}`

    default:
      return `${hostName}${apiPrefix}${apiVersion}`
  }
}

const defaultAxios = (options) => {
  //   const accessToken = '';
  const userId = localStorage.getItem('userId')
  const commonHeaders = {
    'X-App-Auth': localStorage.getItem('d'),
    ...(userId && { 'X-Authorization': userId }),
  }

  const {
    url = '',
    method = 'GET',
    params = {},
    data = {},
    headers = {},
    apiVersion = currentVersion,
    subSystemName = SUB_SYSTEM_NAME.INDUCT,
  } = options

  const baseUrl = getBaseUrl(subSystemName, apiVersion)
  return axios({
    url: `${baseUrl}${url}`,
    method,
    params,
    data,
    headers: { ...commonHeaders, ...headers },
    timeout: apiTimeout,
    //14.2.22 - Uncomment this when cookies are involved
    // withCredentials: true,
  })
}

export default defaultAxios
