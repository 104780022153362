import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Input, Button, Select, Spin } from 'antd'
import { getUserDetails, updateUserDetails } from '../../../services/user'
import { redirectToCubytsApps } from '../../../utils/common'
import './UserDetailsForm.less'
import UploadImage from '../../../components/uploadImage/UploadImage'
import { Helmet } from 'react-helmet'

const userRoleOption = [
  {
    label: 'Founder',
    value: 'Founder',
  },
  {
    label: 'Design Manager',
    value: 'Design Manager',
  },
  {
    label: 'Designer',
    value: 'Designer',
  },
  {
    label: 'Product Manager',
    value: 'Product Manager',
  },
  {
    label: 'Developer',
    value: 'Developer',
  },
  {
    label: 'Others',
    value: 'Others',
  },
]

const otherRoleKeywordConstants = 'Others'
const userRolesConstants = userRoleOption.map((role) => role.value)

const UserDetailsForm = (props) => {
  const { t } = useTranslation()
  const { userToken, oldPath } = props
  const [userRole, selectUserRole] = useState()
  const [loading, setLoading] = useState('')
  const [userDetails, setUserDetails] = useState({})
  const [image, setImage] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    setLoading('initialLoad')
    fetchUserDetails()
  }, [])

  const fetchUserDetails = () => {
    getUserDetails(userToken)
      .then((resutls) => {
        if (resutls && resutls.status === 200) {
          const {
            firstName,
            lastName,
            workspaceName,
            userRole: roleType,
          } = resutls.data
          const finalUserRole =
            !roleType || userRolesConstants.includes(roleType)
              ? roleType
              : otherRoleKeywordConstants
          if (finalUserRole) {
            selectUserRole(finalUserRole)
          }
          form.setFieldsValue({
            firstName,
            lastName,
            workspaceName,
            userRole: finalUserRole,
            otherUserRole: roleType,
          })
          setUserDetails(resutls.data)
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading('')
      })
  }

  const onUserRoleSelected = (value) => {
    if (value === otherRoleKeywordConstants) {
      form.setFieldsValue({
        otherUserRole: '',
      })
    }
    selectUserRole(value)
  }

  const onFinish = (values = {}) => {
    let currentUserRole = values.userRole
    if (userRole === otherRoleKeywordConstants) {
      currentUserRole = values.otherUserRole
    }
    delete values['otherUserRole']
    const { workspaceId, tenantId } = userDetails
    setLoading('update')
    const formData = new FormData()
    formData.append(
      'metaData',
      JSON.stringify({ ...values, userRole: currentUserRole })
    )
    if (image) {
      formData.append('file', image)
    }
    
    redirectToCubytsApps(userToken, oldPath)

    // uncommnt this to enable onboarding d
    // updateUserDetails({
    //   reqBody: formData,
    //   jwtToken: userToken,
    //   workspaceId,
    //   tenantId,
    // })
    //   .then((results) => {
    //     if (results && results.status === 200) {
    //       redirectToCubytsApps(userToken, oldPath)
    //     }
    //   })
    //   .catch(() => {})
    //   .finally(() => setLoading(''))
  }
  return (
    <Spin spinning={loading === 'initialLoad'}>
      <div className="userDetailsContainer">
        <Helmet>
          <title>{t('browserTitle.welcomePage')}</title>
        </Helmet>
        <div className="titleText title">{t('userDetails.title')}</div>
        <UploadImage onChange={setImage} />
        <Form
          form={form}
          name="userDetailsForm"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          size="large"
        >
          <Form.Item
            label={t('userDetails.firstName')}
            name="firstName"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('userDetails.firstNameError'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('userDetails.lastName')}
            name="lastName"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('userDetails.lastNameError'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('userDetails.roleTitle')}
            name="userRole"
            validateTrigger={['onChange']}
            rules={[
              {
                required: true,
                message: t('userDetails.roleTitleError'),
              },
            ]}
          >
            <Select
              value={userRole}
              options={userRoleOption}
              onSelect={onUserRoleSelected}
            />
          </Form.Item>

          {userRole === otherRoleKeywordConstants && (
            <Form.Item
              label={t('userDetails.otherUserRole')}
              name="otherUserRole"
              validateTrigger={['onBlur']}
              rules={[
                {
                  required: true,
                  message: t('userDetails.roleTitleError'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {/* As discussed with Nehal hide the workspace input field(do not remove it) */}
          {/* <Form.Item
            label={t('userDetails.workspaceName')}
            name="workspaceName"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('userDetails.workspaceNameError'),
              },
            ]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={!!(loading && loading !== 'initialLoad')}
              block
              className={'letsStartButton'}
            >
              {t('userDetails.letsStart')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  )
}

UserDetailsForm.propTypes = {
  userToken: PropTypes.string,
  oldPath: PropTypes.string,
}

UserDetailsForm.defaultProps = {
  userToken: '',
  oldPath: '',
}
export default UserDetailsForm
