import axios from '../utils/axios'
import momentTimezone from 'moment-timezone'
import { SUB_SYSTEM_NAME } from '../utils/constants'
import {
  getJWTFromLocalStorage,
  getUserIdFromLocalStorage,
} from '../utils/common'

export const getUserDetails = (jwtToken) =>
  axios({
    url: `/users`,
    subSystemName: SUB_SYSTEM_NAME.BYTS,
    headers: {
      'X-App-Auth': jwtToken,
    },
  })

export const updateUserDetails = ({
  reqBody,
  jwtToken,
  workspaceId,
  tenantId,
} = {}) =>
  axios({
    url: `/users/details`,
    params: {
      multipartRequest: true,
    },
    method: 'PUT',
    data: reqBody,
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-App-Auth': jwtToken,
      'X-WorkspaceId': workspaceId,
      'X-TenantId': tenantId,
    },
  })

export const updateUserDetailsV2 = ({ reqBody, jwtToken } = {}) =>
  axios({
    apiVersion: '/v2',
    url: `/users`,
    method: 'PUT',
    data: reqBody,
    headers: {
      'Content-Type': 'application/json',
      'X-App-Auth': jwtToken,
    },
  })

export const authenticateUser = (emailId = '', password = '', token = '') =>
  axios({
    apiVersion: '/v2',
    url: `/users/authenticate`,
    method: 'POST',
    data: {
      emailId,
      password,
    },
    headers: {
      'X-Recaptcha': token,
      'X-Timezone': momentTimezone.tz.guess(),
    },
  })

export const resetUserPassword = (reqBody = {}, jwtToken = '') =>
  axios({
    url: `/users/password/reset`,
    method: 'POST',
    data: reqBody,
    headers: {
      'X-App-Auth': jwtToken,
    },
  })

export const resetForgotUserPassword = (reqBody = {}, passwordJWTToken = '') =>
  axios({
    url: `/users/password/forgot/reset`,
    method: 'POST',
    data: reqBody,
    headers: {
      'X-App-Auth': passwordJWTToken,
    },
  })

export const sendForgotPasswordMail = (emailId = '', token = '') =>
  axios({
    url: `/users/password/forgot/mail`,
    method: 'POST',
    data: {
      emailId,
    },
    headers: {
      'X-Recaptcha': token,
    },
  })

export const validateJWTTokenService = () =>
  axios({
    url: `/validate/jwt`,
    method: 'GET',
  })

export const signUpUser = (requestBody = {}, token = '') =>
  axios({
    apiVersion: '/v2',
    url: `/users`,
    method: 'POST',
    data: { ...requestBody },
    headers: {
      'X-Recaptcha': token,
      'X-Timezone': momentTimezone.tz.guess(),
    },
  })

export const decrypt = (requestBody = {}, token = '') =>
  axios({
    subSystemName: SUB_SYSTEM_NAME.BYTS,
    apiVersion: '/v1',
    url: `/cubytsGeek/crypto/decrypt/`,
    method: 'POST',
    data: { ...requestBody },
    headers: {
      'X-Recaptcha': token,
    },
  })
export const verifyUser = (token = '') =>
  axios({
    apiVersion: '/v2',
    url: `/users/email/verify`,
    method: 'POST',
    headers: {
      'X-App-Auth': token,
    },
  })

export const verifyUserResend = (userId = '', token = '') =>
  axios({
    apiVersion: '/v2',
    url: `/users/email/verify/resend`,
    method: 'POST',
    headers: {
      'X-Recaptcha': token,
      'X-Authorization': userId,
    },
  })

export const verifyUserEmailValidation = (userId = '', token = '') =>
  axios({
    apiVersion: '/v2',
    url: `/users/email/validate`,
    method: 'POST',
    headers: {
      'X-Recaptcha': token,
      'X-Authorization': userId,
    },
  })

export const validateUserEmailId = ({ emailsId = [] }) =>
  axios({
    apiVersion: '/v2',
    url: `/users/emails/validate`,
    params: { emailsId },
    method: 'GET',
  })

export const updateAuthTokenForChallengeRequest = ({ codeChallenge = '' }) => {
  const authToken = getJWTFromLocalStorage()
  const userId = getUserIdFromLocalStorage()
  return axios({
    apiVersion: '/v1',
    url: `/users/authenticate/pkce`,
    method: 'PUT',
    subSystemName: SUB_SYSTEM_NAME.COMMON,
    data: { codeChallenge, authToken },
    headers: {
      'X-Authorization': userId,
    },
  })
}
