import React from 'react'
import PropTypes from 'prop-types'
import { Result } from 'antd'

const ErrorPage = (props) => {
  const { extra, title, subTitle, icon } = props
  return (
    <Result
      icon={<img alt='error-icon' src={icon} />}
      title={title}
      {...(subTitle && { subTitle })}
      {...(extra && { extra })}
    />
  )
}

ErrorPage.propTypes = {
  extra: PropTypes.node,
  subTitle: PropTypes.node,
}

ErrorPage.propTypes = {
  extra: null,
  subTitle: null,
}
export default ErrorPage
