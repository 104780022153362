import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import PasswordToolTip from '../../pages/signin/passwordForm/passwordToolTip/PasswordToolTip'
import {
  passwordCaptialRuleRegex,
  passwordNumberRuleRegex,
  passwordRegex,
} from '../../utils/constants'
import { useViewPort } from '../../utils/hooks'

const CommonConfirmPassword = ({ form }) => {
  const { isMobileScreen } = useViewPort()
  const [showPasswordToolTip, setShowPasswordToolTip] = useState(false)
  const [passwordRule, setPasswordRule] = useState({
    length: false,
    capitalLetter: false,
    number: false,
    // specialLetter: false,
  })
  const { t } = useTranslation()

  const compareToNewPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('resetPassword.confirmPasswordNotMatchErrorMessage'))
    } else {
      callback()
    }
  }

  const passwordRegexCheck = (e) => {
    const value = e.target.value
    let lengthCheck = false,
      capitalLetter = false,
      number = false
    // specialLetter = false
    if (value && value.length > 7) {
      lengthCheck = true
    }

    if (passwordCaptialRuleRegex.test(value)) {
      capitalLetter = true
    }

    if (passwordNumberRuleRegex.test(value)) {
      number = true
    }

    // if (passwordSpecialLetterRuleRegex.test(value)) {
    //   specialLetter = true
    // }

    setPasswordRule({
      lengthCheck,
      capitalLetter,
      number,
      // specialLetter,
    })
  }

  return (
    <>
         <Tooltip
          trigger={'hover'}
          visible={showPasswordToolTip}
          placement={isMobileScreen? 'topRight':'right'}
          color={'#252835'}
          title={<PasswordToolTip {...passwordRule} />}
        >
          <Form.Item
            label={t('resetPassword.newPassword')}
            name="newPassword"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('resetPassword.newPasswordErrorMessage'),
              },
              {
                pattern: passwordRegex,
                message: t('resetPassword.newPasswordInvalidMessage'),
              },
            ]}
          >
            {/* <Tooltip
            trigger={'hover'}
            visible={showPasswordToolTip}
            placement="right"
            color={'#FFF'}
            title={<PasswordToolTip {...passwordRule} />}
          > */}
            <Input.Password
              onFocus={() => setShowPasswordToolTip(true)}
              onBlur={() => setShowPasswordToolTip(false)}
              onChange={passwordRegexCheck}
            />
          </Form.Item>
        </Tooltip>
      
      <Form.Item
        label={t('resetPassword.confirmPassword')}
        name="confirmPassword"
        validateTrigger={['onBlur']}
        //   className={'passwordInputWrapper'}
        rules={[
          {
            required: true,
            message: t('resetPassword.confirmPasswordErrorMessage'),
          },
          {
            validator: compareToNewPassword,
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  )
}

CommonConfirmPassword.propTypes = {}

export default CommonConfirmPassword
